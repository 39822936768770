import React, {useEffect, useRef, useState} from "react";
import {Table, notification, DefaultTablePagination} from "../../components/Elements/appUtils";
import {fetchDayBookFxn} from "./action"
import PageHeader from "../../components/Elements/pageHeader";
import moment from "moment";
import SearchCustomer from "../customer/chooseCustomer/searchCustomer";
import { PaymentTypes } from "../../components/_utils/appUtils";
import { fetchBank } from "../customer/actions";

// Name	Payment Type	Group Name	Gst	Type	Transaction Type	Quantity	Recieved Amount	Net Amount	Balance
function DayBook() {
    const [dayBookData, setDayBookData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [showFilter, setShowFilter] = useState(false)
    const [searchName, setSearchName] = useState("")
    const [searchPaymentType, setSearchPaymentType] = useState("")
    const [searchGroupName, setSearchGroupName] = useState("")
    const [searchGst, setSearchGst] = useState("")
    const [searchType, setSearchType] = useState("")
    const [searchTransactionType, setSearchTransactionType] = useState("")
    const [customerId, setCustomerId] = useState("")
    const [bankDatas, setBankDatas] = useState([])
    let [total, setTotal] = useState("")
    const [selectedPaymentType, setSelectedPaymentType] = useState("");
    let [dates, setDates] = useState({
        fromDate: "",
        toDate: "",
    });
    let tableRef = useRef();
    const handleFilter = async () => {
        if (tableRef.current) {
            tableRef.current.reload();
            apiRequest()
        }
    };
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            try {
                if (dates.fromDate && dates.toDate) {
                    const fromDateStartOfDay = new Date(dates.fromDate);
                    fromDateStartOfDay.setHours(0, 0, 0, 0);
                    const toDateEndOfDay = new Date(dates.toDate);
                    toDateEndOfDay.setHours(23, 59, 59, 999);
                    params.date = {
                        $gte: new Date(fromDateStartOfDay),
                        $lte: new Date(toDateEndOfDay),
                    };
                } else {
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);
                    const endOfDay = new Date();
                    endOfDay.setHours(23, 59, 59, 999);
                    params.date = {
                        $gte: today,
                        $lte: endOfDay,
                    };
                }

                if (customerId) {
                    params.customerId = customerId;
                }

                const data = await fetchDayBookFxn({...params});
                setDayBookData(data.data)
                setFilteredData(data.data)
                setTotal(data.total)
                resolve(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };
    // const getBankNameById = (bankId, banks) => {
    //     console.log(banks, "Rrrrrrrrrrrr")
    //     let bank = bankDatas.find((bank) => bank._id === bankId);
    //     return bank ? bank.bankName : 'Unknown Bank';
    // };
    const fetchBanks = async () => {
        let {data} = await fetchBank({
            type: "bank",
            results: 100,
            count: 100,
        });
      setBankDatas(data)
    };
    useEffect(() => {
        fetchBanks()
    },[])
   
    
    const columns = [
        {
            title: "Sr. No",
            dataIndex: "serialNo",
            key: "serialNo",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (text) => <span>{moment(text).format("DD-MMM-YY")}</span>,
        },
        {
            title: "Name",
            dataIndex: "customerId",
            key: "customerId",
            render: (text) => (
                <span>
                    {text?.name}
                    {text?.mobile && ` (${text.mobile})`}
                </span>
            )
        },
        // {
        //     title: "Number",
        //     dataIndex: "customerId",
        //     key: "customerId",
        //     width:'90px',
        //     render: (text) => <span>{text?.mobile}</span>,
        // },

        // {
        //     title: "Payment Type",
        //     dataIndex: "paymentType",
        //     key: "paymentType",
        //     render: (text, record, index) => {
        //
        //         if (record.salesId) {
        //             return (
        //                 <>
        //                     {record.salesId?.paidToUser?.map((item) => (
        //                         <div key={item._id}>
        //                             {console.log(item, "paymentTupe")}
        //                             {item.paymentType}
        //                         </div>
        //                     ))}
        //                 </>
        //             );
        //         } else if (record.purchaseId) {
        //
        //             return (
        //                 <div>
        //                     {record.purchaseId.paymentType}
        //                 </div>
        //             );
        //         } else {
        //             return null;
        //         }
        //     }
        //
        //
        // },
        // {
        //     title: "Group Name",
        //     dataIndex: "groupName",
        //     key: "groupName",
        //     render: (text, record, index) => {
        //
        //         if (
        //             record.customerId &&
        //             (record.customerId.customerGroupName === "BANK ACCOUNT" || record.customerId.customerGroupName === "CASH IN HAND")
        //         ) {
        //             return record.customerId.customerGroupName;
        //         } else {
        //             return null;
        //         }
        //     }
        // },
        // {
        //     title: "Gst",
        //     dataIndex: "gstAmount",
        //     key: "gstAmount",
        //     render: (text, record, index) => {
        //
        //         if (record.salesId) {
        //             return (
        //                 <>
        //                     {record.salesId.gstAmount}
        //                 </>
        //             );
        //         } else if (record.purchaseId) {
        //
        //             return (
        //                 <div>
        //                     {record.purchaseId.gstAmount}
        //                 </div>
        //             );
        //         } else {
        //             return null;
        //         }
        //     }
        // },

        {
            title: "Details",
            dataIndex: "type",
            key: "type",
            render: (text, record, index) =>{
                return (
                    <>
                        <span>{record  ?.type}</span>
                        <span>{record && record.transferToId && ` ➡️ ${record.transferToId?.name}`}</span>

                    </>
                )
            }
        },
        // {
        //     title: "Bank Name",
        //     dataIndex: "bankId",
        //     key: "bankId",
        //     render: (transactionType, record) => {
        //         return    <div>
        //      {record.salesId.paidToUser.map((item) => {
        //             return(
        //                 <>
        //                <span>{getBankNameById(item._id, bankDatas)}</span>
        //                 </>
        //             )
        //         })
        //     }
        //        </div>;
        //     }
        // },
       
        {
            title: "Credit",
            dataIndex: "transactionType",
            key: "credit",
            render: (transactionType, record) => {
              if (transactionType === "Credit") {
                return <div style={{  color: '#03C03C', padding: '5px', fontWeight: 'bold' }}>{record.netAmount}</div>;
              }
              return null;
            },
          },
          {
            title: "Debit",
            dataIndex: "transactionType",
            key: "debit",
            render: (transactionType, record) => {
              if (transactionType === "Debit") {
                return <div style={{  color: 'red', padding: '5px', fontWeight: 'bold' }}>{record.netAmount}</div>;
              }
              return null;
            },
          },
        // {
        //     title: "Recieved Amount",
        //     dataIndex: "paidAmount",
        //     key: "gstAmount",
        //     render: (text, record, index) => {
        //
        //         if (record.salesId) {
        //             return (
        //                 <>
        //                     {record.salesId.paidAmount}
        //                 </>
        //             );
        //         } else if (record.purchaseId) {
        //
        //             return (
        //                 <div>
        //                     {record.purchaseId.paidAmount}
        //                 </div>
        //             );
        //         } else {
        //             return null;
        //         }
        //     }
        // },
        // {
        //     title: "Amount",
        //     dataIndex: "netAmount",
        //     key: "netAmount",
        //     render: (text) => <span>Rs. {text}</span>,
        //
        // },
    ];

    const filterDayBook = () => {
        setShowFilter(true)
        if (!searchName && !searchPaymentType && !searchGroupName && !searchGst &&
            !searchTransactionType) {
            // setDayBookData([])
            notification.error({message: "please enter name"});
            setShowFilter(false)
            return
        }
        const filteredDayBook = dayBookData?.filter((item) => {
            item.customerId?.name.toLowerCase().includes(searchName.toLowerCase()) &&
            item.purchaseId?.paymentType?.toLowerCase().includes(searchPaymentType.toLowerCase()) &&
            item.groupName?.toLowerCase().includes(searchGroupName.toLowerCase()) &&
            item.salesId?.gstAmountt?.includes(searchGst) &&
            item.type?.toLowerCase().includes(searchType.toLowerCase()) &&
            item.transactionType?.toLowerCase().includes(searchTransactionType.toLowerCase())
        })
        setDayBookData(filteredDayBook)
    }

    const clearDayBookFilters = () => {
        setSearchName("")
        setSearchPaymentType("")
        setSearchGroupName("")
        setSearchGst("")
        setSearchType("")
        setSearchTransactionType("")
        setShowFilter(false)
        setDayBookData(filteredData)
        if (tableRef.current.reload) {
            tableRef.current.reload();

        }
    }

    const Print = () =>{
        let printContents = document.getElementById('printable').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload()
    }

    return (
        <PageHeader
            title={`Day book (total : ${total})`}
        >
            <div className="card">
                <div className="card-body">
                    <div className="mb-3 row">
                        <div className={"col-md-2"} style={{marginTop: "13px"}}>
                            <SearchCustomer
                                type={["customer", "patient"]}
                                customerId={customerId}
                                callback={(clientData) => {
                                    if (clientData && clientData._id) {
                                        setCustomerId(clientData._id);
                                    }
                                }}
                            />
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="searchCategory" className="form-label"/>
                            <input
                                type="date"
                                className="form-control "
                                value={dates.fromDate}
                                onChange={(e) =>
                                    setDates({...dates, fromDate: e.target.value})
                                }
                            />
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="searchCategory" className="form-label"/>
                            <input
                                type="date"
                                className="form-control "
                                value={dates.toDate}
                                onChange={(e) => setDates({...dates, toDate: e.target.value})}
                            />
                        </div>
                    </div>
                    <div className="mb-3 search-buttons">
                        <div className="search-wrap pe-2">
                            <button className="btn btn-info" onClick={handleFilter}>
                                Search
                            </button>
                        </div>
                        <div className="search-wrap pe-2">
                            <button className="btn btn-secondary" onClick={clearDayBookFilters}>
                                Clear
                            </button>
                        </div>
                        <div className="search-wrap">
                            <button className="btn btn-info" onClick={Print}>
                                Print
                            </button>
                        </div>
                    </div>
                    <div id='printable'>
                        <Table
                            apiRequest={apiRequest}
                            columns={columns}
                            pagination={DefaultTablePagination({}, 500)}
                            ref={tableRef}
                            position={'top'}
                        />
                    </div>

                </div>
            </div>
        </PageHeader>
    );
}

export default DayBook;
