import AddProduct from "./pages/products/addProduct";
import AddCompany from "./pages/company/addCompany";
import Branch from "./pages/branch/branchList";
import AddBranch from "./pages/branch/addBranch";
import AddDoctor from "./pages/doctor/addDoctor";
import Doctor from "./pages/doctor/doctorList";
import AddBrand from "./pages/brands/addBrand";
import AddGroup from "./pages/customerGroup/addGroup";
import AddCategory from "./pages/category/addCategory";
import AddSaleCategory from "./pages/category/addSaleCategory";
import CompanyData from "./pages/company/company";
import ProductData from "./pages/products/products";
import Service from "./pages/products/services";
import AddCustomer from "./pages/customer/addCustomer";
import Customers from "./pages/customer/customerList";
import Banks from "./pages/customer/bankList";
import Clients from "./pages/customer/clientList";
import Patients from "./pages/customer/patientsList";
import AddBank from "./pages/customer/addbank";
import Dashboard from "./pages/dashboard";
import AddPurchase from "./pages/purchase/addPurchase";
import Purchase from "./pages/purchase/purchase";
import Sale from "./pages/sales/sales";
import StockRequest from "./pages/stockRequest/stockRequestList";
import AddSales from "./pages/sales/addSales";
import AddStock from "./pages/stockRequest/addStockRequest";
import AddPatient from "./pages/customer/addPatient";
import Visits from "./pages/doctor/visits";
import addReception from "./pages/reception/addReception";
import Reception from "./pages/reception/reception";
import EditCompany from "./pages/company/editCompany";
import EditService from "./pages/products/editService";
import EditBranch from "./pages/branch/editBranch";
import editProduct from "./pages/products/editProduct";
import AddSymptom from "./pages/symptom/addSymptom";
import EditSymptom from "./pages/symptom/editSymptom";
import EditCustomer from "./pages/customer/editCustomer";
import AddTaken from "./pages/patients/components/addtaken";
import AddFrequency from "./pages/patients/components/frequency";
import PrescriptionList from "./pages/doctor/prescriptionList";
import EditPrescription from "./pages/doctor/editPrescription";
import Transfer from "./pages/transfer/transfer";
import addTransfer from "./pages/transfer/addTransfer";
import EditProfile from "./pages/company/editProfile";
import DayBook from "./pages/daybook/dayBook";
import editSale from "./pages/sales/editSale";
// import AddExpenseCategory from "./pages/expense/addExpenseCategory";
import AddEmployee from "./pages/employee/addEmployee";
// import AddExpense from "./pages/expense/addJournalEntry";
import JournalEntryList from "./pages/expense/journalEntryList";
import EmployeeList from "./pages/employee/employeeList";
import EditEmployee from "./pages/employee/editEmployee";
import EditExpense from "./pages/expense/editExpense";
import EmployeeProfile from "./pages/employee/employeeProfile";
import AddCreditNote from "./pages/creditNote/addCreditNote";
import CreditNoteList from "./pages/creditNote/creditNoteList";
import PatientList from "./pages/customer/patientsList";
import addPatient from "./pages/customer/components/addPatient";
import AddLead from "./pages/lead/addLead";
import LeadList from "./pages/lead/leadList";
import FollowUp from "./pages/lead/followUp";
import AddFollowup from "./pages/lead/addFollowup";
import AddUser from "./pages/purchaseUser/addUser";
import EditDoctor from "./pages/doctor/editDoctor";
import UserList from "./pages/purchaseUser/userList";
import EditLead from "./pages/lead/editLead";
import EditBrand from "./pages/brands/editBrand";
import EditCategory from "./pages/category/editCategory";
import EditSaleCategory from "./pages/category/editSaleCategory";
import EditTaken from "./pages/patients/components/editTaken";
// import EditExpenseCategory from "./pages/expense/editExpenseCategory";
import EditFrequency from "./pages/patients/components/editFrequency";
import EditBank from "./pages/customer/editBank";
import EditPatient from "./pages/customer/editPatient";
import EditReception from "./pages/reception/editReception";
import EditUser from "./pages/purchaseUser/editUser";
// import ROI from "./pages/roi";
import GenerateSale from "./pages/generateSale";
// import ConvertROI from "./pages/convertROI";
import Slip from "./pages/doctor/slip";
import PaymentIn from "./pages/payment/paymentIn";
import PaymentOut from "./pages/payment/paymentOut";
import FollowUpList from "./pages/lead/followUpList";
// import AddClientComponent from "./pages/customer/components/addClientComponent";
import AddClient from "./pages/customer/addClient";
import AddJournalEntry from "./pages/expense/addJournalEntry";
import AddUpdatedServicePriceAndList from "./pages/updatedServicePrice/addUpdatedServicePriceAndList";
import ChangePassword from "./pages/company/createPAssword";
import ProductReport from "./pages/dailyProductReport/dailyProductReport";
import DebitNoteRequest from "./pages/debitNoteRequest/debitNoteRequestList";
import AddDebitNote from "./pages/debitNoteRequest/addDebitNote";
import EditDebitNote from "./pages/debitNoteRequest/editDebitNote";


export let menuData = [
  {
    name: "Dashboard",
    path: "/dashboard",
    key: "dashboard",
    icon: "monitor",
    authority: ["admin", "company", "branch", "reception","doctor", "headBranch","purchaseUser", "transferUser"],
    component: Dashboard,
  },
  // {
  //   name: "Leads",
  //   icon: "folder",
  //   key: "lead",
  //   authority: ["admin", "company", "branch", "headBranch"],
  //   subMenu: [
  //     {
  //       name: "Add Lead",
  //       path: "/add-lead",
  //       component: AddLead,
  //     },
  //     {
  //       name: "Lead list",
  //       path: "/lead-list",
  //       component: LeadList,
  //     },
  //   ],
  // },

  {
    name: "Lead list",
    key: "leadList",
    path: "/lead-list",
    icon: "account-search",
    authority: ["admin", "company", "branch", "headBranch","reception"],
    component: LeadList,
    
  },
  {
    name: "Add Lead",
    key: "Addlead",
    path: "/add-lead",
    icon: "account-search",
    authority: ["admin", "company", "branch", "headBranch","reception"],
    component: AddLead,
    dontShowOnMenu:true
  },
  {
    name: "followUp",
    key: "followUp",
    path: "/follow-up",
    icon: "account-search",
    authority: ["reception"],
    component: FollowUp,
    dontShowOnMenu:true
  },
  {
    name: "followUpList",
    key: "followUpList",
    path: "/follow-up-list",
    icon: "account-search",
    authority: ["reception"],
    component: FollowUpList,
    dontShowOnMenu:true
    
 
  },
  {
    name: "addFollowup",
    key: "addFollowup",
    path: "/add-followup",
    icon: "account-search",
    authority: ["admin", "company", "branch", "headBranch"],
    component: AddFollowup,
    dontShowOnMenu:true
  },
  {
    name: "editLead",
    key: "editLead",
    path: "/edit-lead",
    icon: "account-search",
    authority: ["admin", "company", "branch", "headBranch"],
    component: EditLead,
    dontShowOnMenu:true
  },
  {
    name: "editPatient",
    key: "editPatient",
    path: "/edit-patient",
    icon: "account-search",
    authority: ["admin", "company", "branch", "headBranch"],
    component: EditPatient,
    dontShowOnMenu:true
  },
  {
    name: "editSaleCategory",
    key: "editSaleCategory",
    path: "/edit-sale-category",
    icon: "account-search",
    authority: ["admin", "company", "branch", "headBranch"],
    component: EditSaleCategory,
    dontShowOnMenu:true
  },
  {
    name: "editFrequency",
    key: "editFrequency",
    path: "/edit-frequency",
    icon: "account-search",
    authority: ["admin", "company", "branch", "headBranch"],
    component: EditFrequency,
    dontShowOnMenu:true
  },
  {
    name: "editTaken",
    key: "editTaken",
    path: "/edit-addtaken",
    icon: "account-search",
    authority: ["admin", "company", "branch", "headBranch"],
    component: EditTaken,
    dontShowOnMenu:true
  },
  // {
  //   name: "editExpenseCategory",
  //   key: "editExpenseCategory",
  //   path: "/edit-expense-category",
  //   icon: "account-search",
  //   authority: ["admin", "company", "branch", "headBranch"],
  //   component: EditExpenseCategory,
  //   dontShowOnMenu:true
  // },
  {
    name: "addUser",
    key: "addUser",
    path: "/add-user",
    icon: "account-search",
    authority: ["admin", "company", "branch", "headBranch"],
    component: AddUser,
    dontShowOnMenu:true
  },
 
  {
    name: "Users",
    icon: "folder",
    key: "users",
    authority: ["company"],
    subMenu: [
      {
        name: "Doctor",
        path: "/doctor",
        component: Doctor,
      },
      {
        name: "Reception",
        path: "/reception",
        component: Reception,
      },
      {
        name: "Branch",
        path: "/branch",
        component: Branch,
      },
    
      {
        name: "User List",
        path: "/userList",
        component: UserList,
      },
      {
        name: "Patient",
        path: "/patient",
        component: PatientList,
      },
      {
        name: "Employee",
        path: "/employee",
        component: EmployeeList,
      },
      {
        name: "Customers",
        path: "/customers",
        component: Customers,
      },
      {
        name: "Banks",
        path: "/banks",
        component: Banks,
      },

    ],
  },
  {
    name: "Users",
    icon: "folder",
    key: "users",
    authority: ["headBranch", "branch"],
    subMenu: [
      {
        name: "Doctor",
        path: "/doctor",
        component: Doctor,
      },
      {
        name: "Reception",
        path: "/reception",
        component: Reception,
      },
      {
        name: "Patient",
        path: "/patient",
        component: PatientList,
      },
      {
        name: "Customers",
        path: "/customers",
        component: Customers,
      },
      {
        name: "Banks",
        path: "/banks",
        component: Banks,
      },
      {
        name: "Employee",
        path: "/employee",
        component: EmployeeList,
      },
      {
        name: "Other Heads",
        path: "/clients",
        component: Clients,
      },
    ],
  },

  {
    name: "Journal Entry",
    key: "journal-entry",
    path: "/journal-entry",
    icon: "account-search",
    authority: ["admin", "company", "branch", "headBranch"],
    component: JournalEntryList,
  },
  {
    name: "Add Journal Entry",
    key: "addjournalEntry",
    path: "/add-journal-entry",
    icon: "account-search",
    authority: ["admin", "company", "branch", "headBranch"],
    component: AddJournalEntry,
    dontShowOnMenu:true
  },
  {
    name: "editEmployee",
    key: "editEmployee",
    path: "/edit-employee",
    icon: "account-search",
    authority: ["admin", "company", "branch", "headBranch"],
    component: EditEmployee,
    dontShowOnMenu:true
  },
  {
    name: "employeeProfile",
    key: "employeeProfile",
    path: "/emp-profile",
    icon: "account-search",
    authority: ["admin", "company", "branch", "headBranch"],
    component: EmployeeProfile,
    dontShowOnMenu:true
  },
  {
    name: "editExpense",
    key: "editExpense",
    path: "/edit-expense",
    icon: "account-search",
    authority: ["admin", "company", "branch", "headBranch"],
    component: EditExpense,
    dontShowOnMenu:true
  },

  {
    name: "Companies",
    key: "company",
    path: "/company-list",
    icon: "book",
    authority: ["admin"],
    component: CompanyData,
  },
  {
    name: "Add Taken",
    key: "addtaken",
    path: "/add-taken",
    icon: "book",
    authority: ["admin", "company", "branch", "reception","doctor","headBranch"],
    component: AddTaken,
    dontShowOnMenu:true

  },
  {

    name: "Prescription",
    key: "prescription",
    path: "/prescription",
    icon: "bank",
    authority: ["company", "reception","doctor"],
    component: PrescriptionList,
    dontShowOnMenu:true
  },

  
  {
    name: "addEmployee",
    key: "addEmployee",
    path: "/add-employee",
    icon: "book",
    authority: ["admin", "company", "branch", "headBranch"],
    component: AddEmployee,
    dontShowOnMenu:true
  },


  {
    name: "Add Frequency",
    key: "addfrequency",
    path: "/add-frequency",
    icon: "book",
    authority: ["admin", "company", "branch", "reception","doctor","headBranch"],
    component: AddFrequency,
    dontShowOnMenu:true
  },

  {
    name: "Add Company",
    key: "addCompany",
    path: "/add-company",
    icon: "book",
    authority: ["admin"],
    component: AddCompany,
  },
  {
    name: "Edit Company",
    key: "EditCompany",
    path: "/edit-company",
    icon: "book",
    authority: ["admin"],
    component: EditCompany,
    dontShowOnMenu: true,
  },
  // {
  //   name: "ROI",
  //   key: "ROI",
  //   path: "/roi",
  //   icon: "book",
  //   authority: ["headBranch"],
  //   component: ROI,
  //   // dontShowOnMenu: true,
  // },
 
  {
    name: "Slip",
    key: "Slip List",
    path: "/slip",
    icon: "book",
    authority: ["reception"],
    component: Slip,
    //  dontShowOnMenu: true,
  },
  {
    name: "Edit Profile",
    key: "EditProfile",
    path: "/edit-profile",
    icon: "book",
    authority: ["admin", "company", "branch","headBranch"],
    component: EditProfile,
     dontShowOnMenu: true,
  },
  {
    name: "Edit Service",
    key: "EditService",
    path: "/edit-service",
    icon: "book",
    authority: ["admin", "company", "branch", "reception","doctor","headBranch"],
    component: EditService,
    dontShowOnMenu: true,
  },
  {
    name: "Edit Prescription",
    key: "EditPrescription",
    path: "/edit-prescription",
    icon: "book",
    authority: ["admin", "company", "branch", "reception","doctor","headBranch"],
    component: EditPrescription,
    dontShowOnMenu: true,
  },
  {
    name: "Edit branch",
    key: "Editbranch",
    path: "/edit-branch",
    icon: "book",
    authority: ["admin", "company", "branch", "reception","doctor","headBranch"],
    component: EditBranch,
    dontShowOnMenu: true,
  },
  {
    name: "Edit Customer",
    key: "Editcustomer",
    path: "/edit-customer",
    icon: "book",
    authority: ["admin", "company", "branch", "reception","doctor","headBranch"],
    component: EditCustomer,
    dontShowOnMenu: true,
  },
  {
    name: "Edit Symptom",
    key: "Editsymptom",
    path: "/edit-symptom",
    icon: "book",
    authority: ["admin", "company", "branch", "reception","doctor","headBranch"],
    component: EditSymptom,
    dontShowOnMenu: true,
  },
  {
    name: "Edit Brand",
    key: "EditBrand",
    path: "/edit-brand",
    icon: "book",
    authority: ["admin", "company", "branch","headBranch"],
    component: EditBrand,
    dontShowOnMenu: true,
  },
  {
    name: "Edit Bank",
    key: "EditBank",
    path: "/edit-bank",
    icon: "book",
    authority: ["admin", "company", "branch","headBranch"],
    component: EditBank,
    dontShowOnMenu: true,
  },
  {
    name: "Edit Category",
    key: "EditCategory",
    path: "/edit-category",
    icon: "book",
    authority: ["admin", "company", "branch","headBranch"],
    component: EditCategory,
    dontShowOnMenu: true,
  },
  {
    name: "Edit Product",
    key: "Editproduct",
    path: "/edit-product",
    icon: "book",
    authority: ["admin", "company", "branch", "reception","doctor","headBranch"],
    component: editProduct,
    dontShowOnMenu: true,
  },
  // {
  //   name: "Prescription Detail",
  //   key: "prescriptiondetail",
  //   path: "/prescription-detail",
  //   icon: "book",
  //   authority: ["admin", "company", "branch", "reception","doctor"],
  //   component: PrescriptionDetail,
  //   dontShowOnMenu: true,
  // },
  {
    name: "Branch",
    key: "branch",
    path: "/branch",
    icon: "source-branch",
    authority: ["company"],
    component: Branch,
    dontShowOnMenu: true,

  },
  {
    name: "Add Branch",
    key: "addBranch",
    path: "/add-branch",
    icon: "book",
    authority: ["company"],
    component: AddBranch,
    dontShowOnMenu: true,
  },
 
  {
    name: "Banks",
    key: "banks",
    path: "/banks",
    icon: "bank",
    authority: ["branch","headBranch"],
    component: Banks,
    // dontShowOnMenu: true,
  },

  {
    name: "Groups",
    key: "group",
    path: "/group",
    icon: "bank",
    authority: ["admin"],
    component: AddGroup,
    // dontShowOnMenu: true,
  },
  {
    name: "Settings",
    icon: "folder",
    key: "settings",
    authority: ["company"],
    subMenu: [
      {
        name: "Brands",
        path: "/brand",
        component: AddBrand,
      },
      {
        name: "Categories",
        path: "/category",
        component: AddCategory,
      },
      {
        name: "Sale Categories",
        path: "/sale-category",
        component: AddSaleCategory,
        authority: ["headBranch"],
      },
      {
        name: "Symptom",
        path: "/symptom",
        component: AddSymptom,
      },
      {
        name: "Add Taken",
        path: "/add-taken",
        component: AddTaken,
      },
      {
        name: "Add Frequency",
        path: "/add-frequency",
        component: AddFrequency,
      },
    ],
  },
  {
    name: "Settings",
    icon: "folder",
    key: "settings",
    authority: ["headBranch","branch"],
    subMenu: [
  
      {
        name: "Sale Categories",
        path: "/sale-category",
        component: AddSaleCategory,
        authority: ["headBranch"],
      },
      {
        name: "Symptom",
        path: "/symptom",
        component: AddSymptom,
      },
    ],
  },


  {
    name: "Product List",
    key: "productList",
    path: "/product-list",
    icon: "truck",
    authority: ["company", "doctor", "headBranch","branch"],
    component: ProductData,
  },
  {
    name: "Service List",
    key: "serviceList",
    path: "/service-list",
    icon: "book-open-page-variant",
    authority: ["company", "doctor"],
    component: Service,
  },
  // {
  //   name: "Stock List",
  //   key: "stocklist",
  //   path: "/stock-list",
  //   icon: "dropbox",
  //   authority: ["branch","headBranch"],
  //   component: StockData,
  // },
  {
    name: "Purchase",
    icon: "folder",
    key: "branchPurchase",
    authority: ["branch"],
    subMenu: [
      {
        name: "Purchase",
        path: "/purchase",
        component: Purchase,
      },
      {
        name: "Stock Request",
        path: "/stockRequest",
        component: StockRequest,
      },
    ],
  },
  {
    name: "Add Purchase",
    key: "addPurchase",
    path: "/add-purchase",
    icon: "cart-outline",
    authority: ["company"],
    component: AddPurchase,
    dontShowOnMenu: true,
  },
  {
    name: "Purchase",
    key: "addPurchase",
    path: "/purchase",
    icon: "cart-outline",
    authority: ["purchaseUser", "company"],
    component: Purchase,
  
  },
  {
    name: "Stock Requests",
    key: "stockRequest",
    path: "/stockRequest",
    icon: "account-multiple-plus",
    authority: ["transferUser"],
    component: StockRequest,

  },
  
  {
    name: "Stock Requests",
    key: "stockRequest",
    path: "/stockRequest",
    icon: "account-multiple-plus",
    authority: ["branch", "company","headBranch"],
    component: StockRequest,
    dontShowOnMenu: true
  },
  {
    name: "Add Stock Request",
    key: "addStockRequest",
    path: "/add-stockRequest",
    icon: "book",
    authority: ["branch", "company"],
    component: AddStock,
    dontShowOnMenu: true,
  },
  {
    name: "Sale",
    key: "sale",
    path: "/sale",
    icon: "cart",
    authority: ["company", "branch","reception","headBranch"],
    component: Sale,
    dontShowOnMenu: true
  },
  {
    name: "Add Sales",
    key: "addSales",
    path: "/add-sales",
    icon: "cart",
    authority: ["company", "branch","reception","headBranch"],
    component: AddSales,
    dontShowOnMenu: true,
  },
  {
    name: "Generate Sales",
    key: "generateSales",
    path: "/generate-sale",
    icon: "cart",
    authority: ["admin","company", "branch"],
    component: GenerateSale,
    dontShowOnMenu: true,
  },
  {
    name: "Transfer",
    key: "transfer",
    path: "/transfer",
    icon: "cart",
    authority: ["transferUser", "company", "headBranch","branch"],
    component: Transfer,
    // dontShowOnMenu: true
  },
  {
    name: "Day Book",
    key: "daybook",
    path: "/daybook",
    icon: "hospital",
    authority: ["company", "branch", "headBranch", "reception"],
    component: DayBook,
    // dontShowOnMenu: true
  },
  {
    name: "Add Transfer",
    key: "addTransfer",
    path: "/add-transfer",
    icon: "cart",
    authority: ["transferUser", "company"],
    component: addTransfer,
    dontShowOnMenu: true,
  },
  {
    name: "Payment In",
    key: "paymentIn",
    path: "/payment-in",
    icon: "cart",
    authority: [ "company",  "headBranch",'reception'],
    component: PaymentIn,
    // dontShowOnMenu: true,
  },
  {
    name: "Payment Out",
    key: "paymentOut",
    path: "/payment-Out",
    icon: "cart",
    authority: [ "company","headBranch",'reception'],
    component: PaymentOut,
    // dontShowOnMenu: true,
  },
  {
    name: "Edit Sale",
    key: "editSale",
    path: "/edit-sale",
    icon: "cart",
    authority: ["company","branch","headBranch",'reception'],
    component: editSale,
    dontShowOnMenu: true,
  },
  // {
  //   name: "ROI List",
  //   key: "ROI List",
  //   path: "/roi-list",
  //   icon: "cart",
  //   authority: ["branch"],
  //   component: ConvertROI,
  //   // dontShowOnMenu: true,
  // },
  {
    name: "Edit Doctor",
    key: "editDoctor",
    path: "/edit-doctor",
    icon: "cart",
    authority: ["company","branch","headBranch"],
    component: EditDoctor,
    dontShowOnMenu: true,
  },

  {
    name: "Edit Reception",
    key: "editReception",
    path: "/edit-reception",
    icon: "cart",
    authority: ["company","branch","headBranch"],
    component: EditReception,
    dontShowOnMenu: true,
  },
  {
    name: "Edit User",
    key: "editUser",
    path: "/edit-user",
    icon: "cart",
    authority: ["company","branch","headBranch"],
    component: EditUser,
    dontShowOnMenu: true,
  },
  {
    name: "Sale",
    icon: "folder",
    key: "sale",
    authority: ["company","branch"],
    subMenu: [
      {
        name: "Sale List",
        path: "/sale",
        component: Sale,
      },
      {
        name: "Add Sale",
        path: "/add-sales",
        component: AddSales,
      },
      {
        name: "Stock Request",
        path: "/stockRequest",
        component: StockRequest,
      },
    ],
  }, {
    name: "Patient",
    icon: "folder",
    key: "patients",
    authority: ["company", "branch","reception","headBranch"],
    subMenu: [
      {
        name: "Patient List",
        path: "/patient",
        component: PatientList,
      },
      {
        name: "Add Patient",
        path: "/add-patient",
        component: AddPatient,
      },
    ],
  },
  {
    name: "Sale",
    icon: "folder",
    key: "sale",
    authority: ["headBranch",'reception'],
    subMenu: [
      {
        name: "Sale List",
        path: "/sale",
        component: Sale,
      },
      {
        name: "Add Sale",
        path: "/add-sales",
        component: AddSales,
      },

    ],
  },

  {
    name: "Credit Note",
    key: "creditNote",
    path: "/creditNote",
    icon: "hospital",
    authority: ["company","branch","reception","headBranch"],
    component: CreditNoteList,

  },
  {
    name: "Add Credit Note",
    key: "addcreditNote",
    path: "/addcreditNote",
    icon: "hospital",
    authority: ["company","branch","reception","headBranch"],
    component: AddCreditNote,
    // dontShowOnMenu:true

  },
  {
    name: "Debit Note",
    key: "debitNote",
    path: "/debitNote",
    icon: "hospital",
    authority: ["company","branch","reception","headBranch"],
    component: DebitNoteRequest,
    // dontShowOnMenu:true
  },
  {
    name: "Add Debit Note",
    key: "addDeditNote",
    path: "/add-debitNote",
    icon: "hospital",
    authority: ["branch","reception","headBranch"],
    component: AddDebitNote,
    dontShowOnMenu:true
  },
  {
    name: "Edit Debit Note",
    key: "editDebitNote",
    path: "/edit-debitNote",
    icon: "hospital",
    authority: ["company","branch","reception","headBranch"],
    component: EditDebitNote,
    dontShowOnMenu:true

  },
  {
    name: "Add Doctor",
    key: "addDoctor",
    path: "/add-doctor",
    icon: "hospital",
    authority: ["company","branch","headBranch"],
    component: AddDoctor,
    dontShowOnMenu: true,
  },
  {
    name: "Doctors",
    key: "doctor",
    path: "/doctor",
    icon: "hospital",
    authority: ["company","branch","headBranch"],
    component: Doctor,
    dontShowOnMenu: true,
  },
  {
    name: "Add Patient",
    key: "addPatient",
    path: "/add-patient",
    icon: "human-male-female",
    authority: ["company", "branch","reception","headBranch"],
    component: AddPatient,
    dontShowOnMenu: true,
    
  },
  {
    name: "Patient",
    key: "patient",
    path: "/patient",
    icon: "human-male-female",
    authority: ["company", "branch","headBranch","reception"],
    component: Patients,
    dontShowOnMenu: true
  },
  {
    name: "Add Reception",
    key: "addReception",
    path: "/add-reception",
    icon: "book",
    authority: ["company","branch","headBranch"],
    component: addReception,
    dontShowOnMenu: true,
  },
  {
    name: "Reception",
    key: "reception",
    path: "/reception",
    icon: "deskphone",
    authority: ["company","branch","headBranch"],
    component: Reception,
    dontShowOnMenu: true,
  },


  {
    name: "Visits",
    key: "visit",
    path: "/visit",
    icon: "book",
    authority: ["doctor","reception", "headBranch"],
    component: Visits,
  },
  {
    name: "Add Product",
    key: "addProduct",
    path: "/add-product",
    icon: "book",
    dontShowOnMenu: true,
    authority: ["company"],
    component: AddProduct,
  },
  {
    name: "Update Service Price",
    key: "updateServicePrice",
    path: "/updateServicePrice",
    icon: "book",
    authority: ["headBranch", 'branch'],
    component: AddUpdatedServicePriceAndList,
  },

  {
    name: "Daily Stock Report",
    key: "productStockReport",
    path: "/product-report-list",
    icon: "book",
    authority: ["headBranch", 'branch','company'],
    component: ProductReport  ,
  },
  {
    name: "Add Customer",
    key: "addCustomer",
    path: "/add-customer",
    icon: "book",
    authority: ["company","branch","headBranch"],
    component: AddCustomer,
    dontShowOnMenu: true,
  },

  {
    name: "Add Bank",
    key: "addBank",
    path: "/add-bank",
    icon: "book",
    authority: ["company","branch","headBranch"],
    component: AddBank,
    dontShowOnMenu: true,
  },
  {
    name: "Add Client",
    key: "add-client",
    path: "/add-client",
    icon: "book",
    authority: ["company","branch","headBranch"],
    component: AddClient,
    dontShowOnMenu: true,
  },

  {
    name: "Change Password",
    key: "changePassword",
    path: "/reset-password",
    icon: "book",
    authority: ["admin", "company", "branch","headBranch"],
    component: ChangePassword,
    dontShowOnMenu: true,
  },
];
