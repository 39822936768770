import React from "react";
import {menuData} from "../../menu";
import {useNavigate} from "react-router-dom";
import moment from "moment";

const image1 = require("../../components/assets/images/faces/face1.jpg");
const SingleMenu = (props) => {
    let {menu, events} = props;
    return !menu.dontShowOnMenu ? (
        <li className="nav-item">
            <a className="nav-link" onClick={() => events.openLink(menu.path)}>
                <span className="menu-title">{menu.name}</span>
                <i className={`mdi mdi-${menu.icon} menu-icon`}></i>
            </a>
        </li>
    ) : null;
};
const NestedMenu = (props) => {
    let {menu, events} = props;
    let menuKey = `ui-${menu.key}`;
    return (
        <li className="nav-item" key={menu.key + moment()}>
            <a className="nav-link collapsed"
               data-bs-toggle="collapse"
               href={`#${menuKey}`}
               aria-expanded="false"
               aria-controls={menuKey}>
                <span className="menu-title">{menu.name}</span>
                <i className="menu-arrow"></i>
                <i className="fa fa-chevron-down arrow_cls"></i>
            </a>
            <div className="collapse" id={menuKey}>
                <ul className="nav flex-column sub-menu">
                    {menu.subMenu.map((child) => {
                        return !child.dontShowOnMenu ? (
                            <li className="nav-item" key={child.key}>
                                <a
                                    className="nav-link"
                                    onClick={() => events.openLink(child.path)}
                                >
                                    {child.name}
                                </a>
                            </li>
                        ) : null;
                    })}
                </ul>
            </div>
        </li>
    );
};
export default function SideBar() {
    const navigate = useNavigate();
    let user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;

    let events = {
        openLink: (path) => {
            navigate(path);
        },
        checkAuth: (data) => {
            return data && data.authority && data.authority.length
                ? data.authority.includes(user.userType)
                    ? true
                    : false
                : true;
        },
    };

    return (
        <>
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
                <ul className="nav">
                    {/*<li className="nav-item nav-profile">
            <a href="#" className="nav-link">
              <div className="nav-profile-image">
                <img src={image1} alt="profile" />
                <span className="login-status online"></span>
              </div>
              <div className="nav-profile-text d-flex flex-column">
                <span className="font-weight-bold mb-2">David Grey. H</span>
                <span className="text-secondary text-small">
                  Project Manager
                </span>
              </div>
              <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
            </a>
          </li>*/}
                    <br/>

                    {menuData.map((item) => {
                        let {subMenu = []} = item;
                        let checkM = ((subMenu && subMenu.length ? (
                            <NestedMenu menu={item} events={events} key={item.key}/>
                        ) : (
                            <SingleMenu menu={item} events={events} key={item.key}/>
                        )));
                        return events.checkAuth(item) ? checkM : null;
                    })}
                </ul>
            </nav>
        </>
    );
}
