import { apiUrl } from "../../../setting";

export const getCustomerDataUrl = () => {
  return `${apiUrl}/routes/customer/customerList`;
};

export const getBankDataUrl = () => {
  return `${apiUrl}/routes/customer/getBank`;
};
export const getClientDataUrl = () => {
  return `${apiUrl}/routes/customer/getExpenseClient`;
};

export const addCustomerUrl = () => {
  return `${apiUrl}/routes/customer/addCustomer`;
};

export const addPatientUrl = () => {
  return `${apiUrl}/routes/customer/addPatient`;
};

export const generateSlipUrl = () => {
  return `${apiUrl}/routes/customer/generateVisitSlip`;
};

export const getSymptomDataUrl = () => {
  return `${apiUrl}/routes/symptom/getSymptomDataByCompanyId`;
};
export const singleCustomerUrl = (customerId) => {
  return apiUrl + "/routes/customer/getSingleCustomer/" + customerId;
};
export const singlePatientUrl = (id) => {
  return apiUrl + "/routes/customer/getSinglepatient/" + id;
};
export const updateCustomerUrl = () => {
  return `${apiUrl}/routes/customer/updateCustomer`;
};
export const deleteCustomerUrl = (id) => {
  return apiUrl + "/routes/customer/deleteCustomer/" + id;
};
export const getVisitUrl = () => {
  return `${apiUrl}/routes/prescription/fetchSinglePatientVisit`;
};
export const singleBankUrl = (id) => {
  return apiUrl + "/routes/customer/getSingleBank/" + id;
};
export const updateBankUrl = () => {
  return `${apiUrl}/routes/customer/updateBank`;
};
export const updatePatientUrl = () => {
  return `${apiUrl}/routes/customer/updatepatient`;
};
export const getTransaction = (customerId) => {
  return apiUrl + "/routes/transfer/fetchtransactionByCustomerId/" + customerId;
};

export const getJournalEntryClients = () => {
  return `${apiUrl}/routes/customer/getJournalEntryClients`;
};
