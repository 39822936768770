import React, { useEffect, useRef, useState } from "react";
import {
    DefaultTablePagination,
    Modal,
    notification,
    Popconfirm,
    Table,
    Tooltip
} from "../../components/Elements/appUtils";
import {fetchCreditNote, fetchDebitNote, fetchSale, updateDebitStatus} from "./actions";
import PageHeader from "../../components/Elements/pageHeader";
import moment from "moment";
import { getToken } from "../../request";
import {getDeleteDebitUrl, getProductReport, getProductReportCompany, getSingleUserUrl} from "../purchase/api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../actions/loader";
import SearchCustomer from "../customer/chooseCustomer/searchCustomer";
import {getStateByCountry} from "../../components/_utils/appUtils";
import {fetchSaleCategory} from "../category/actions";
import {getItemLocalstorage} from "../../components/_utils/_utils";
import {addCreditNoteFxn} from "../creditNote/actions";

function DebitNoteList() {
    let tableRef = useRef();
    const dispatch =  useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [debitNoteId, setDebitNoteId] = useState(null);
    const [Report, setReport] = useState([]);
    const navigate = useNavigate();
    const userType = getItemLocalstorage("user")["userType"];
    const [ProductReport, setProductReport] = useState([]);


    const [customerData, setCustomerData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [showFilter, setShowFilter] = useState(false)
    const [searchVendor, setSearchVendor] = useState("")
    const [searchInvoice, setSearchInvoice] = useState("")
    const [searchCreditDate, setSearchCreditDate] = useState("")
    const [searchState, setSearchState] = useState("")
    const [final,setFinal] = useState('');
    const [saleCategory, setSaleCategory] = useState([]);
    const [saleCategoryId, setSaleCategoryId] = useState("");
    const [customerDetails, setCustomerDetails] = useState({
        customerName: "",
        customerState: "",
        customerInvoice: "",
        customerNumber: "",
        purchaseDate: "",
    });
    const [customerId, setCustomerId] = useState(0);
    const [searchByState, setSearchByState] = useState({
        stateCode: '',
        stateName: ''
    });
    const [stateList, setStateList] = useState([]);
    const fetchSaleFrom = async () => {
        const {data} = await fetchSaleCategory({});
        setSaleCategory(data);
    };

    const apiRequest = (params={}) => {
        return new Promise(async (resolve) => {
            try {
                if(customerId)
                {
                    params['customerId'] =  customerId;}
                if (searchByState.stateName && searchByState.stateCode) {
                    params.stateOfSupply = searchByState.stateCode;
                }
                if(searchInvoice)
                    params['invoiceNumber'] = searchInvoice;
                if(saleCategoryId)
                {
                    params.saleCategory=saleCategoryId;
                }
                const data = await fetchDebitNote({ ...params });

                setCustomerData(data.data);
                setFinal(data.data);
                resolve(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };
    useEffect(()=>{
        fetchSaleFrom();
    },[])
    const fetchcustomerdata = () => {
        const allrecordofcustomer = customerData.filter(
            (item) => item._id == debitNoteId
        );

        allrecordofcustomer.map((i) => {
            console.log(i,"cafpjpj");
            setCustomerDetails({
                customerName: i.customerId.name,
                customerState: i.customerId.state,
                customerNumber: i.customerId.mobile,
                customerInvoice: i.invoiceNumber,
                purchaseDate: i.purchaseDate,
            });
            return;
        });
    };

    const fetchProductReport = async () => {
        try {
            let config = {
                params: { debitNoteId },
                ...(await getToken()),
            };
            dispatch(showLoader())
            const response = await axios.get(getProductReportCompany(), config);
            setReport(response.data.data);
            dispatch(hideLoader())
        } catch (error) {
            console.error("Error getting the data:", error);
        }
    };

    useEffect(() => {
        apiRequest();
    }, []);

    useEffect(() => {
        fetchProductReport();
        fetchcustomerdata();
    }, [debitNoteId]);

    const columns = [
        {
            title: "Sr. No",
            dataIndex: "serialNo",
            key: "serialNo",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Branch",
            dataIndex: "branchId",
            key: "branchId",
            render: (text) => <span>{text?.name}</span>,
            hidden: userType == 'branch'

        },
        {
            title: "Company",
            dataIndex: "customerId",
            key: "customerId",
            render: (text) => <span>{text?.name}</span>,
            hidden: userType == 'company'
        },

        {
            title: "Invoice Number",
            dataIndex: "invoiceNumber",
            key: "invoiceNumber",
        },
        {
            title: "Debit Note Date",
            dataIndex: "debitNoteDate",
            key: "debitNoteDate",
            render: (text) => <span>{moment(text).format("DD/MMM/YY")}</span>,
        },
        {
            title: "State",
            dataIndex: "customerId",
            key: "customerId",
            render: (text) => <span>{text?.state}</span>,
        },
        {
            title: "Total Quantity",
            dataIndex: "quantity",
            key: "quantity",
        },
        {
            title: "Total Amount",
            dataIndex: "totalAmount",
            key: "totalAmount",
            render: (text) => <span>Rs. {text}</span>,
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "sale Category",
            dataIndex: "saleCategory",
            key: "saleCategory",
            render: (text, record) => {
                return (
                    <>
                        {record && record.saleCategory &&
                        record.saleCategory.name ?
                            record.saleCategory.name
                            : null}
                    </>)
            },
        },
        {
            title: "Received Amount",
            dataIndex: "paidAmount",
            key: "paidAmount",
            render: (text) => <span>Rs. {text}</span>,
        },
        {
            title: "Balance",
            dataIndex: "balance",
            key: "balance",
            render: (text) => <span>Rs. {text}</span>,
        },
        {
            title: "Actions",
            dataIndex: "description",
            key: "description",
            render: (text, record) => (
                <>
                    <Tooltip title={"View"}>
                        <a
                            className={"empty_btn"}
                            onClick={() => {
                                setIsModalOpen(true);
                                setDebitNoteId(record._id);
                            }}
                        >
                            <i className={"fa fa-eye far "}></i>
                        </a>
                    </Tooltip>
                    {userType == "company" && record.status == "pending" ? (
                        <Popconfirm
                            title={"Are your sure, you want to Convert To Credit Note?"}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => {
                                handleConvert(record)
                            }}
                        >
                            <button
                                className="btn btn-success btn-xs m-2"
                                // onClick={() => {
                                //     setTimeout(() => {
                                //         handleConvert(record)
                                //     }, 1000);
                                // }}
                            >
                                Convert
                            </button>
                        </Popconfirm>
                    ) : null}

                    {userType != "company" && record.status == "pending" ? (
                        <button
                            className="btn btn-success btn-xs"
                            onClick={() => {
                                // setRequestId(record._id);
                                // ConvertSale(record._id, record.branchId);
                                navigate(`/edit-debitNote?_id=${record._id}`)
                            }}
                        >
                            Edit
                        </button>
                    ) : null}

                    {record.status == "pending" && userType != "company" ? (
                        <Popconfirm
                            title={"Are your sure, you want to Cancel Debit Note?"}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => {
                               handleDeleteDebit(record);
                            }}
                        >
                            <button
                                className="btn btn-danger btn-xs m-2"
                            >
                                Cancel
                            </button>
                        </Popconfirm>
                    ) : null}
                </>
            ),
        },
    ];
    const handleDeleteDebit = async (data)=>{
        try {
            let config = {
                ...(await getToken()),
            };
            dispatch(showLoader())
            const response = await axios.post(getDeleteDebitUrl(),data, config);
            dispatch(hideLoader())
            if (response) {
                tableRef.current.reload();
                notification.success({ message: response.data.message });
                return;
            } else {
                notification.error({ message: response.data.message });
            }
        }catch (e){
            console.log(e)
        }
    }
    const handleConvert = async(record) =>{
        try {
            record.creditNoteDate = moment()
            let config = {
                params: { debitNoteId:record._id },
                ...(await getToken()),
            };
            dispatch(showLoader())
            const response = await axios.get(getProductReportCompany(), config);
            dispatch(hideLoader())

            if (response){
                let reportData = response.data.data.map((item)=>{
                    delete item._id;
                    delete item.debitNoteId;
                    return item
                });
                if (reportData){
                    record.productReports = reportData
                }
            }
            const res = await axios.get(getSingleUserUrl(), {params:{_id:record.branchId._id}, ...(await getToken())});
            console.log(res,'res');
            if(res && res.data && res.data._id){
                record.customerId = res.data._id;
                let x = await dispatch(addCreditNoteFxn(record));
                if (x && x.success) {
                    let params = { _id: record._id };
                    let response = await updateDebitStatus(params);
                    if (response && response.success) {  // Check if the response is successful
                        notification.success({ message: response.message });
                        navigate("/creditNote");
                    } else {
                        notification.error({ message: response.message || "Failed to update debit status" });
                    }
                } else {
                    notification.error({ message: x.message });
                }
            }

        }catch (e) {
            console.log(e,"error")
        }
    }
    const filterCreditNote = () => {
        setShowFilter(!showFilter);
    };
    const clearCreditFilters = () => {
        setSearchVendor("");
        setSearchInvoice("");
        setSearchCreditDate("");
        setCustomerId('');
        setSaleCategoryId("");
        setSearchByState({
            stateName: '',
            stateCode: ''
        });
        setShowFilter(!showFilter);
        // setCustomerData(filteredData);
    };
    const printFxn = () => {
        const printContents = document.getElementById("printable").innerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
    };
    const events = {
        getState: () => {
            let resp = getStateByCountry('India');
            setStateList(resp);
        },
    };
    useEffect(() => {
        events.getState();
    }, []);
    const checkUserType = getItemLocalstorage("user")["userType"];
    return (
        <PageHeader
            title={<div style={{ display: 'flex', alignItems: 'center' }}>
                <div>Debit Note</div>
            </div>}
            extraLink={
                checkUserType == "headBranch" || checkUserType == "company"
                    ? []
                    : [{
                        name: "Add debit-Note",
                        link: "/add-debitNote",
                    },
                    ]
            }
        >
            <div className="card">
                <div className="card-body">
                    <div className="mb-3 row">
                        {/*<div className={"col-md-2 pe-0 search-filters"} style={{marginTop: "13px"}}>*/}
                        {/*  <SearchCustomer*/}
                        {/*      type={["customer", "patient"]}*/}
                        {/*      customerId={customerId}*/}
                        {/*      callback={(clientData) => {*/}
                        {/*        if (clientData && clientData._id) {*/}
                        {/*          setCustomerId(clientData._id);*/}
                        {/*        }*/}
                        {/*      }}*/}
                        {/*  />*/}
                        {/*</div>*/}

                        <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '7px' }}>
                            <div className={"col-md-12 pe-0"} style={{marginTop: "13px",height: "45px"}}>
                                <SearchCustomer
                                    type={["customer", "patient"]}
                                    customerId={customerId}
                                    callback={(clientData) => {
                                        if (clientData && clientData._id) {
                                            setCustomerId(clientData._id);
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '10px' }}>

                            <input
                                type="text"
                                className="form-control search-invoice-input "
                                style={{marginTop: "17px",height: "45px"}}
                                placeholder={'Search by Invoice'}
                                value={searchInvoice}
                                onChange={(e) => setSearchInvoice(e.target.value)}
                            />
                        </div>


                        <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '10px' }}>
                            <div className="col-md-12 " style={{marginTop: 17}}>
                                <select
                                    placeholder="State Of Supply"
                                    style={{height: "45px"}}
                                    className="form-control"
                                    value={searchByState.stateName} // Assuming searchByState is your state object
                                    onChange={(e) => {
                                        const selectedState = stateList.find(state => state.name === e.target.value);
                                        setSearchByState({
                                            ...searchByState,
                                            stateName: selectedState ? selectedState.name : '',
                                            stateCode: selectedState ? selectedState.stateCode : ''
                                        });
                                    }}
                                    required
                                >
                                    <option value="">State Of Supply</option>
                                    {stateList.map((state) => (
                                        <option key={state.name} value={state.name}>
                                            {state.name}
                                        </option>
                                    ))}
                                </select>

                            </div>
                        </div>
                        <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '10px' }}>
                            <div className="col-md-12 " style={{marginTop: 17}}>
                                <select

                                    style={{height: "45px"}}
                                    className="form-control"
                                    onChange={({ target: { value } }) => {setSaleCategoryId(value);}}
                                    value={saleCategoryId}
                                >
                                    <option value="" disabled selected>
                                        Select Sale Category
                                    </option>
                                    {saleCategory.map((category) => (
                                        <option key={category._id} value={category._id}>
                                            {category.name}
                                        </option>
                                    ))}
                                </select>

                            </div>
                        </div>
                    </div>

                    <div className="mb-3 search-buttons">
                        <div className="search-wrap pe-2">
                            <button className="btn btn-info" onClick={filterCreditNote}>
                                Search
                            </button>
                        </div>
                        <div className="search-wrap">
                            <button className="btn btn-secondary" onClick={clearCreditFilters}>
                                Clear
                            </button>
                        </div>
                        <div className="search-wrap">
                            <button className="btn btn-success" onClick={printFxn}>
                                Print
                            </button>
                        </div>
                    </div>
                    <div  id="printable">
                        <div style={{ display: 'flex', alignItems: 'end' }}>
                            <div style={{ fontSize:"smaller", marginTop:"5px" }}>
                            </div>
                        </div>
                        {showFilter?<div> <Table
                            apiRequest={apiRequest}
                            position={"top"}
                            ref={tableRef}
                            columns={columns}
                            pagination={{...DefaultTablePagination(), defaultPageSize: 50}}
                        /></div>:<div>
                            <div> <Table
                                apiRequest={apiRequest}
                                position={"top"}
                                ref={tableRef}
                                columns={columns}
                                pagination={{...DefaultTablePagination(), defaultPageSize: 50}}
                            /></div>
                        </div>}

                    </div>
                </div>
            </div>
            {isModalOpen && (
                <Modal
                    title={"Product List"}
                    width={"70%"}
                    visible={isModalOpen === true}
                    onClose={() => setIsModalOpen(false)}
                >
                    <div className="d-inline-block border border-2">
                        <div className="item-flex">
                            <h5 className="m-3 d-block">
                                Name:{" "}
                                <span className="font-weight-normal ms-2">
                  {customerDetails.customerName}
                </span>
                            </h5>
                            <h5 className="m-3 d-block">
                                State:{" "}
                                <span className="font-weight-normal ms-2">
                  {customerDetails.customerState}
                </span>
                            </h5>
                            <h5 className="m-3 d-block">
                                Invoice No:{" "}
                                <span className="font-weight-normal ms-2">
                  {customerDetails.customerInvoice}
                </span>
                            </h5>
                            <h5 className="m-3 d-block">
                                Number:{" "}
                                <span className="font-weight-normal ms-2">
                  {customerDetails.customerNumber}
                </span>
                            </h5>
                            <h5 className="m-3 d-block">
                                Date:{" "}
                                <span className="font-weight-normal ms-2">
                  {moment(customerDetails.purchaseDate).format("DD-MMM-YYYY")}
                </span>
                            </h5>
                        </div>
                    </div>
                    <table className="table table-bordered mt-4">
                        <thead>
                        <tr>
                            <td>Sr no.</td>
                            <td>Product Name</td>
                            <td>Report Type</td>
                            <td>Mfg Date</td>
                            <td>Exp Date</td>
                            <td>Batch</td>
                            <td>Qty.</td>
                            <td>Rate</td>
                            <td>Dis Amt.</td>
                            <td>Taxable Amt.</td>
                            {Report.some(
                                (item) => item.cgstAmount !== 0 && item.sgstAmount !== 0
                            ) ? (
                                <>
                                    <td>CGST Amt.</td>
                                    <td>SGST Amt.</td>
                                </>
                            ) : (
                                <td>IGST Amt.</td>
                            )}
                            <td>Net Amt.</td>
                        </tr>
                        </thead>
                        <tbody>
                        {Report.map((item, index) => (
                            <tr key={item.id}>
                                <td>{index + 1}</td>
                                <td>{item.productName}</td>
                                <td>{item.reportType}</td>
                                <td>{item.manufacturingDate}</td>
                                <td>{item.expiryDate}</td>
                                <td>{item.batchNo}</td>
                                <td>{item.quantity}</td>
                                <td>Rs. {item.price}</td>
                                <td>
                                    Rs. {item.discountAmount} ({item.discount}%)
                                </td>
                                <td>Rs. {item.taxableAmount}</td>
                                {item.cgstAmount === 0 && item.sgstAmount === 0 ? (
                                    <>
                                        <td>
                                            Rs. {item.igstAmount} ({item.gst}%)
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td>
                                            Rs. {item.cgstAmount} ({item.gst / 2}%)
                                        </td>
                                        <td>
                                            Rs. {item.sgstAmount} ({item.gst / 2}%)
                                        </td>
                                    </>
                                )}

                                <td>Rs. {item.netAmount}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </Modal>
            )}
        </PageHeader>
    );
}

export default DebitNoteList;
