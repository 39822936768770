import React, { useEffect, useState } from "react";
import {
  Form,
  HyperLink,
  notification,
} from "../../components/Elements/appUtils";
import moment from "moment";
import { leadSourceOption } from "../../components/_utils/_utils";
import { branchType } from "../../components/_utils/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import { useDispatch } from "react-redux";
import { GetEachFormFields } from "../../components/_utils/formUtils";
import { States, gstTypeList } from "../../components/_utils/appUtils";
import { addCompanyFxn } from "../customer/actions";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { singleLeadFxn, updateLeadFxn } from "./action";
import { type } from "@testing-library/user-event/dist/type";
const EditLead = (props) => {
  const { getFieldValue, setFieldsValue } = props.form;
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [doctorId, setDoctorId] = useState("");

  useEffect(() => {
    checkLeadId();
  }, []);

  const checkLeadId = async () => {
    let searchParams = new URLSearchParams(window.location.search);
    let id = searchParams.get("_id");
    if (id) {
      getSingleLead(id);
      setDoctorId(id);
    }
  };
  const getSingleLead = async (id) => {
    let { data, success } = await dispatch(singleLeadFxn(id));
    console.log(data, "valdata");
    if (success) {
      setTimeout(() => {
        props.form.setFieldsValue({
          name: data.name,

          mobile: data.mobile,

          address: data.address,
          email: data.email,
          state: data.state,
          city: data.city,
          pincode: data.pincode,
          date: data.date ? moment(data.date) : "",
          comments: data.comments,
          leadSource: data.leadSource,
        });
      }, 300);
      setTimeout(() => {
        if (data.gstType == "Registered") {
          setFieldsValue({ gstIn: data.gstIn });
        }
      }, 400);
    }
  };

  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();

    form.validateFields(async (err, valData) => {
      if (!err) {
        valData._id = doctorId;

        let x = await dispatch(updateLeadFxn(valData));
        if (x && x.success) {
          console.log(x, "valdata");
        }
      } else {
        if (err.name) {
          notification.warning({
            message: `Please enter Name`,
          });
          return;
        }

        if (err.email) {
          notification.warning({
            message: `Please enter Email`,
          });
          return;
        }

        notification.warning({
          message: "Fill All Required Fields",
        });
      }
    });
  };

  let inputTypes = {
    fields: [
      {
        key: "name",
        label: "Name *",
        span: "col-md-6",
        placeholder: "Name",
      },
      {
        key: "mobile",
        label: "Mobile No *",
        span: "col-md-6",
        placeholder: "Mobile No",
      },
      {
        key: "state",
        label: "State",
        type: "text",
        span: "col-md-6",
        placeholder: "State",
      },
      {
        key: "city",
        label: "City",
        type: "text",
        span: "col-md-6",
        placeholder: "City",
      },
      {
        key: "pincode",
        label: "pincode",
        type: "text",
        span: "col-md-6",
        placeholder: "Pincode",
      },

      {
        key: "address",
        label: "Address",
        type: "text",
        span: "col-md-6",
        placeholder: "Address",
      },
      {
        key: "date",
        label: "Lead Date",
        showStar: true,
        placeholder: "Date",
        type: "date",
        span: "col-md-6",
        onChange: (x) => {
          props.form.setFieldsValue({
            date: x,
          });
        },
      },
      {
        key: "leadSource",
        label: "Lead Source",
        placeholder: "Please Choose Lead Source",
        type: "select",
        options: leadSourceOption,
        keyAccessor: (x) => x.option,
        valueAccessor: (x) => `${x.option}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            leadSource: x,
          });
        },
      },

      {
        key: "comments",
        label: "Comments",
        type: "text",
        span: "col-md-6",
        placeholder: "Comments",
      },
    ],
  };

  return (
    <>
      <PageHeader title={"Edit Lead"}>
        <div className="card">
          <div className="card-body">
            <Form onSubmit={handleSubmit}>
              <div className="form-elements-wrapper">
                <div className={"row"}>
                  {inputTypes.fields.map((item, key) => {
                    return !item.hidden ? (
                      <div
                        className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                        key={key}
                      >
                        <GetEachFormFields {...props.form} item={item} />
                      </div>
                    ) : null;
                  })}
                </div>
              </div>

              <button type="submit" className="btn btn-gradient-primary me-2">
                Submit
              </button>
              <HyperLink
                className=" ms-4 btn btn-danger"
                link={"/company-list"}
              >
                Cancel
              </HyperLink>
            </Form>
          </div>
        </div>
      </PageHeader>
    </>
  );
};

export default Form.create()(EditLead);
