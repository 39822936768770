import React, {useEffect, useState} from "react";
import {
    Card,
    Form,
    HyperLink,
    InputBox,
    fixed2Digit,
    notification,
    Popconfirm,
} from "../../../components/Elements/appUtils";
import {useDispatch} from "react-redux";
import moment from "moment";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {
    States,
    PaymentTypes,
    patientType,
    gender,
    PatientCategory,
    visitType,
    Countries,
    getStateByCountry,
    getCityByState,
} from "../../../components/_utils/appUtils";
import {getToken} from "../../../request";
import {getVisitUrl} from "../api";
import {
    addPatientFxn,
    fetchBank,
    fetchCustomer,
    generateslipFxn,
} from "../actions";
import {generateSlipUrl} from "../api";
import _ from "lodash";
import {fetchDoctors} from "../../doctor/actions";
import {getGroupDataByCustomerIdUrl} from "../../customerGroup/api";
import axios from "axios";
import ItemListComponent from "./ItemListComponent";
import {getItemLocalstorage, groupNameObj, leadSourceOption} from "../../../components/_utils/_utils";
import ChooseSymptom from "../../symptom";
import ChoosePatient from "../chooseCustomer/choosePatient";
import {fetchSymptom} from "../actions";
import {fetchSaleCategory} from "../../category/actions";
import CustomerDetails from "../../patients/components/customerDetails";
import SaleDetailModal from "../../patients/components/saleDetailModal";
import {getUpdatedServicePriceCheckList} from "../../updatedServicePrice/actions";
import {useLocation} from "react-router-dom";

let initFormData = {bankId: "", paymentType: "", amount: 0, transactionDetails: "", key: moment()}
const AddPatient = (props) => {
    const userSaleCategory = getItemLocalstorage("user")["saleCategory"];
    const [formData, setFormData] = useState([initFormData]);
    const [showDeleteButtons, setShowDeleteButtons] = useState([false]);
    const [includeBalance, setIncludeBalance] = useState(true);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const handleInputChange = (index, field, value) => {
        const newData = [...formData];
        newData[index][field] = value;
        setFormData(newData);
        setShowDeleteButtons((prevButtons) =>
            prevButtons.map((btn, idx) => (idx === index ? true : btn))
        );
    };

    const handleDelete = (index) => {
        const newPayments = [...formData];
        newPayments.splice(index, 1);
        setFormData(newPayments);
        setShowDeleteButtons((prevButtons) =>
            prevButtons.filter((_, idx) => idx !== index)
        );
    };

    const handleAddField = () => {
        const sumAmount = formData.reduce(
            (sum, item) => sum + parseFloat(item.amount) || 0,
            0
        );
        if (sumAmount > getFieldValue("paidAmount")) {
            notification.warning({
                message: "Amount Cannot Be Greater Than Recieving Amount",
            });
            return;
        }
        if (sumAmount == getFieldValue("paidAmount")) {
            notification.warning({
                message: "Enough Amount Added, Cannot Add More Payment Method",
            });
            return;
        }

        const lastData = formData[formData.length - 1];
        if (!lastData.bankId) {
            notification.warning({message: "Please Choose Bank"});
            return;
        }
        if (!lastData.paymentType) {
            notification.warning({message: "Please Choose Payment Type"});
            return;
        }
        if (!lastData.amount) {
            notification.warning({message: "Please Enter Amount"});
            return;
        }

        setFormData([
            ...formData,
            {bankId: "", paymentType: "", amount: "", transactionDetails: ""},
        ]);

        setShowDeleteButtons([...showDeleteButtons, true]);
    };

    let dispatch = useDispatch();
    const [typeOfPatient, setTypeOfPatient] = useState("New Patient");
    const {getFieldValue} = props.form;
    const paidToUserId = getFieldValue("paidToUserId");
    const [groupName, setGroupName] = useState(null);
    const [banks, setBanks] = useState([]);
    const [symptomId, setSymptomId] = useState([]);
    const [symptomIdVal, setSymptomIdVal] = useState("");
    const [dates, setDates] = useState([]);
    const [doctor, setDoctor] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [type, setType] = useState("");
    const [expireDate, setExpireDate] = useState("");
    const [ProductReport, setProductReport] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);

    const updateProductReport = (newProductReport) => {
        setProductReport(newProductReport);
    };

    const events = {
        getState: (countryName) => {
            let resp = getStateByCountry(countryName);
            setStateList(resp);
        },
        getCity: (stateName) => {
            let resp = getCityByState(stateName);
            setCityList(resp);
        },
    };

    const findprodct = () => {
        if (ProductReport && ProductReport.length > 0) {
            const productTypes = ProductReport.map((item) => item.productType);
            const isSlipProduct = productTypes.includes("Slip");
            const isMembershipProduct = productTypes.includes("Membership");

            if (isSlipProduct) {
                const now = moment();
                const startDate = moment(now);
                const expiryDate = moment(now).add(15, "days");
                setStartDate(startDate);
                setExpireDate(expiryDate);
                setType("Slip");
            }
            if (isMembershipProduct) {
                const now = moment();
                const startDate = moment(now);
                const expiryDate = moment(now).add(6, "months");
                setStartDate(startDate);
                setExpireDate(expiryDate);
                setType("Membership");
            }
        }
    };

    let stateOfSupply = "1";
    let companyState = "1";

    let [customerId, setCustomerId] = useState("");
    let [customerRecord, setCustomerRecord] = useState("");
    let [slipRecord, setSlipRecord] = useState([]);
    let [mobileNo, setMobileNo] = useState('');
    const [selectedDoctorId, setSelectedDoctorId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSaleModalOpen, setSaleIsModalOpen] = useState(false);
    const [saleCategory, setSaleCategory] = useState([]);
    let netAmount = 0;
    let quantity = 0;
    let balance = 0;
    const calNetAmount = () => {
        ProductReport.forEach((y) => {
            let paidAmount = getFieldValue("paidAmount");
            paidAmount = paidAmount ? fixed2Digit(paidAmount) : 0;
            netAmount += Number(y.netAmount);
            quantity += Number(y.quantity);
            balance = paidAmount ? netAmount - paidAmount : netAmount;

            if (balance < 0) {
                balance = "Not valid Paid Amount";

                props.form.setFieldsValue({
                    totalAmount: netAmount ? fixed2Digit(netAmount) : 0,
                    quantity: quantity,
                    balance: 0,
                });
            } else {
                props.form.setFieldsValue({
                    totalAmount: netAmount.toFixed(2),
                    quantity: quantity,
                    balance: balance ? balance.toFixed(2) : 0,
                });
            }
        });
    };

    useEffect(() => {
        calNetAmount();
    }, [ProductReport, getFieldValue("paidAmount")]);

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            const data = await fetchDoctors({...params});
            setDoctor(data.data);
        });
    };

    const fetchSaleFrom = async () => {
        const {data} = await fetchSaleCategory({});
        const filteredData = data.filter(item => item.includeBalance === true);
        setSaleCategory(filteredData);
    };

    const fetchBanks = async () => {
        const {data} = await fetchBank({
            type: "bank",
            results: 100,
            count: 100,
        });
        setBanks(data);
    };
    const fetchSymptoms = async () => {
        const {data} = await fetchSymptom();
        setSymptomId(data);
        // setSymptomIdVal(data._id)
    };

    const fetchGroupName = async () => {
        try {
            const response = await axios.get(getGroupDataByCustomerIdUrl(), {
                params: {customerId: paidToUserId},
            });
            setGroupName(response.data.data.name);
        } catch (error) {
            console.error("Error getting the data:", error);
        }
    };
    const fetchVisitModule = async () => {
        try {
            const response = await axios.get(getVisitUrl(), {
                params: {patientId: customerId},
            });
            setSlipRecord(response.data);
        } catch (error) {
            console.error("Error getting the data:", error);
        }
    };

    useEffect(() => {
        apiRequest();
        fetchBanks();
        fetchSymptoms();
        fetchGroupName();
        findprodct();
    }, [getFieldValue("slipType"), ProductReport]);

    const [uhid, setUhId] = useState("");
    const customerData = (params = {}) => {
        return new Promise(async (resolve) => {
            params._id = customerId;
            const data = await fetchCustomer({...params});
            const Patientdata = data.data[0];
            setCustomerRecord(Patientdata);
            props.form.setFieldsValue({
                name: Patientdata.name,
                email: Patientdata.email,
                age: Patientdata.age,
                city: Patientdata.city,
                mobile: Patientdata.mobile,
                address: Patientdata.address,
                state: Patientdata.state,
                pincode: Patientdata.pincode,
                gender: Patientdata.gender,
                uhid: Patientdata.uhid,

            });
            setMobileNo(Patientdata.mobile)
            setUhId(Patientdata.uhid);
        });
    };

    useEffect(() => {
        if (customerId) {
            customerData();
            fetchVisitModule();
        }
    }, [customerId]);

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                let findStateCode = _.find(States, (item) => {
                    return item.name === valData.state;
                });
                if (findStateCode) {
                    valData.stateCode = findStateCode.stateCode;
                    valData.stateOfSupply = findStateCode.stateCode;
                }
                if (!valData.salesDate) {
                    notification.warning({message: "Please Enter Date"});
                    return;
                }
                if (!valData.mobile) {
                    notification.warning({message: "Please Add Mobile Number"});
                    return;
                }
                if (!valData.name) {
                    notification.warning({message: "Please Enter Name"});
                    return;
                }

                if (!/^\d+$/.test(valData.mobile)) {
                    notification.warning({message: "Mobile number should Be numbers"});
                    return;
                }

                if (!valData.gender) {
                    notification.warning({message: "Please Select Gender"});
                    return;
                }

                if (!valData.age) {
                    notification.warning({message: "Please Enter Age"});
                    return;
                }

                if (!valData.saleCategory) {
                    notification.warning({message: "Please Choose Sale Category"});
                    return;
                }

                valData.customerGroupName = groupNameObj.sundryDebtors;
                valData.type = "patient";
                 if(valData.city==='other')
                 {if(valData.notFoundCity)
                     {   valData.city=valData.notFoundCity;
                         delete valData.notFoundCity;}
                     else
                 {
                     valData.city=""
                 }
                 }


                valData.membershipType = "paid";
                if (valData.patientVisit !== "Free" && ProductReport.length === 0) {
                    notification.warning({
                        message: "Please Add Products",
                    });
                    return;
                }

                valData.productReports = ProductReport;
                valData.symptomId = symptomIdVal;
                valData.patientType = typeOfPatient;
                valData.customerId = customerId;
                valData.startDate = startDate;
                valData.expiryDate = expireDate;
                valData.paidToUser = formData;

                const sumFormDataAmount = formData.reduce(
                    (sum, item) => sum + parseFloat(item.amount) || 0,
                    0
                );

                const totalAmount = valData.totalAmount;
                const paidAmount = valData.paidAmount;

                if (valData.paidAmount && isNaN(valData.paidAmount)) {
                    notification.warning({
                        message: "Please Enter Paid Amount in Numbers",
                    });
                    return;
                }

                if (parseFloat(paidAmount) > parseFloat(totalAmount)) {
                    notification.error({
                        message: "paid amount cannot be Greater than total amount",
                    });
                    return null;
                }

                if (parseFloat(valData.paidAmount) < sumFormDataAmount) {
                    notification.error({
                        message: "Paid amount cannot be Greater than total amount",
                    });
                    return;
                } else if (parseFloat(valData.paidAmount) > sumFormDataAmount) {
                    notification.error({
                        message: "Paid amount cannot be less than total amount",
                    });
                    return;
                }
                valData.includeBalance = includeBalance;
                if (valData.saleCategory) {
                    const includeBalance = saleCategory.find((item) => {
                        return item._id === valData.saleCategory;
                    });
                    valData.saleCategoryName = includeBalance.name;
                }
                if (valData.balance == 0) {
                    valData.paymentStatus = "Done";
                } else {
                    valData.paymentStatus = "Pending";
                }
                valData.orderStatus = "";

                valData.finalAmount = valData.totalAmount
                valData.addedOn = valData.salesDate

                if (!valData.doctorId) {
                    delete valData['doctorId']
                }
                if (queryParams.get('_id')) {
                    valData.leadId = queryParams.get('_id')
                }
                let x = await dispatch(addPatientFxn(valData));
                if (x.success) {
                    props.form.setFieldsValue({
                        name: "",
                        doctorId: "",
                        email: "",
                        mobile: "",
                        state: "",
                        city: "",
                        pincode: "",
                        address: "",
                        gender: "",
                        age: "",
                        dateOfBirth: "",
                        patientVisit: "Paid",
                        paidAmount: 0,
                        totalAmount: 0,
                        balance: 0,
                    });
                    setProductReport([]);
                    setCustomerId("");
                    setSlipRecord([]);
                    setFormData([{...initFormData, key: moment()}]);
                    setUhId("");
                    // window.location.reload()
                }
            }
        });
    };
    const generateSlip = async () => {
        try {
            let doctorId
            if (slipRecord) {
                doctorId = slipRecord[0].doctorId._id
            }
            let config = {
                ...(await getToken()),
            };
            const response = await axios.post(
                generateSlipUrl(),
                {
                    customerId: customerId,
                    doctorId: doctorId
                },
                config
            );

            if (!response.data.error) {
                notification.success({message: response.data.message});
                setTimeout(function () {
                    window.location.reload();
                }, 1000);
                return;
            } else {
                notification.error({message: response.data.message});
            }

            //
        } catch (error) {
            console.error("Error generating slip:", error);
        }
    };

    const handleDoctorChange = (doctorId) => {
        setSelectedDoctorId(doctorId);
        // Set the value in the form (if needed)
        props.form.setFieldsValue({
            doctorId: doctorId ? doctorId : undefined,
        });
    };


    let inputTypes = {
        upperFields: [
            {
                key: "salesDate",
                label: "Date*",
                showStar: true,
                type: "date",
                span: "col-md-3",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        salesDate: x,
                    });
                },
            },
            {
                key: "mobile",
                type: "String",
                label: "Mobile No *",
                span: "col-md-3",
                placeholder: "Mobile No",
            },
            {
                key: "name",
                label: "Name*",
                span: "col-md-3",
                placeholder: "Type here..",
            },
            {
                key: "gender",
                label: "Gender*",
                span: "col-md-3",
                type: "select",
                options: gender,
                showSearch: true,
                keyAccessor: (x) => x.value,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        gender: x,
                    });
                },
            },
            {
                key: "dateOfBirth",
                label: "Date of Birth",
                showStar: true,
                placeholder: "Date of Birth",
                type: "date",
                span: "col-md-3",
                onChange: (x) => {
                    const dob = new Date(x);
                    const today = new Date();

                    if (dob > today) {
                        notification.warning({
                            message: "Please Select Valid Date Of Birth",
                        });
                    } else {
                        const age = Math.floor(
                            (today - dob) / (365.25 * 24 * 60 * 60 * 1000)
                        );

                        props.form.setFieldsValue({
                            age: age,
                            dateOfBirth: x,
                        });
                    }
                },
            },
            {
                key: "age",
                label: "Age(In Years)*",
                span: "col-md-3",
                placeholder: "Enter your Age",
            },
            {
                key: "patientVisit",
                label: "Visit Type",
                span: "col-md-3",
                type: "select",
                options: visitType,
                showSearch: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        patientVisit: x,
                    });
                },
            },
            {
                key: "country",
                label: "Country *",
                span: "col-md-3",
                placeholder: "Country",
                type: "select",
                options: Countries,
                showSearch: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        country: x,
                        state: "",
                    });
                    events.getState(x);
                },
            },
            {
                key: "state",
                label: "State",
                span: "col-md-3",
                placeholder: "State",
                type: "select",
                options: stateList,
                showSearch: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        state: x,
                        city: "",
                    });
                    events.getCity(x);
                },
            },
            {
                key: "city",
                label: "City",
                span: "col-md-3",
                placeholder: "City",
                type: "select",
                options: [...cityList , {name:'other'}],
                showSearch: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        city: x,
                    });
                },
                hidden: getFieldValue("country") !== "India",
            },

            {
                key: "notFoundCity",
                label: "Other",
                span: "col-md-3",
                placeholder: "Other city",
                type: "text",
                hidden: getFieldValue('city') !== 'other'
            },
            {
                key: "city",
                label: "City",
                span: "col-md-3",
                placeholder: "City",
                hidden: getFieldValue("country") === "India",
            },
            {
                key: "pincode",
                label: "Pincode",
                span: "col-md-3",
                placeholder: "Pincode",
            },
            {
                key: "email",
                label: "Email",
                span: "col-md-3",
                placeholder: "Type here.. ",
            },

            {
                key: "saleCategory",
                label: "Sale Category *",
                span: "col-md-3",
                placeholder: "Choose One",
                type: "select",
                options: saleCategory,
                showSearch: true,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        saleCategory: x,
                    });
                },
            },
            {
                key: "sourceMedium",
                label: "Source Medium",
                span: "col-md-3",
                placeholder: "Please Choose",
                type: "select",
                options: leadSourceOption,
                keyAccessor: (x) => x.option,
                valueAccessor: (x) => `${x.option}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        sourceMedium: x,
                    });
                },
            },


            {
                key: "doctorId",
                label: "Select Doctor",
                span: "col-md-3",
                placeholder: "select",
                type: "select",
                options: doctor,
                showSearch: true,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    handleDoctorChange(x)
                }
            },


            {
                key: "patientCategory",
                label: "Patient Category",
                span: "col-md-3",
                type: "select",
                options: PatientCategory,
                showSearch: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        PatientCategory: x,
                    });
                },
            },

            {
                key: "address",
                label: "Address ",
                span: "col-md-6",
                type: "textarea",
                placeholder: "Address",
            },
        ],
        lowerFields: [
            {
                key: "totalAmount",
                label: "Total Amount *",
                span: "col-md-3",
                placeholder: "Total Amount",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        totalAmount: netAmount,
                    });
                },
                disabled: true,
            },
            {
                key: "paidAmount",
                label: "Received Amount",
                span: "col-md-3",
                placeholder: "Received Amount",
            },
            {
                key: "balance",
                label: "Balance *",
                span: "col-md-3",
                placeholder: "Balance",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        balance: balance,
                    });
                },
                disabled: true,
            },
        ],
    };
    useEffect(() => {
        props.form.setFieldsValue({
            patientType: "New Patient",
            patientVisit: "Paid",
            country: "India",
            state: "Haryana",
            city: "Karnal",
            salesDate: moment(),
            patientCategory: "Hair",
            paidAmount: 0
        });
        let resp = getStateByCountry("India");
        let resp2 = getCityByState("Haryana");
        if (queryParams.get('name')) {
            props.form.setFieldsValue({
                name: queryParams.get('name')
            });
        }
        if (queryParams.get('mobile')) {
            props.form.setFieldsValue({
                mobile: parseInt(queryParams.get('mobile'))
            });
        }
        setStateList(resp);
        setCityList(resp2);
        fetchSaleFrom();
    }, []);

    useEffect(() => {
        if (getFieldValue("saleCategory") && saleCategory.length !== 0) {
            const includeBalance = saleCategory.find((item) => {
                return item._id === getFieldValue("saleCategory");
            });
            setIncludeBalance(includeBalance.includeBalance);
        }
    }, [getFieldValue("saleCategory")]);


    useEffect(() => {
        if (userSaleCategory) {
            props.form.setFieldsValue({
                saleCategory: userSaleCategory
            })
        }
    }, [userSaleCategory]);


    useEffect(() => {
        if (getFieldValue('paidAmount') > 0) {
            setShowPaymentModal(true)
        } else {
            setShowPaymentModal(false)
        }
    }, [getFieldValue('paidAmount')]);

    return (
        <>
            <div
                className={"row justify-content-start align-items-center gap-5 mb-3"}
            >
                <div className={"col-3"}>
                    <ChoosePatient
                        customerId={customerId}
                        type={["customer", "patient"]}
                        className="form-control patient-type"
                        callback={(clientData) => {
                            setFormData((prevD) => {
                                return [
                                    {...initFormData, key: moment()}
                                ]
                            })

                            if (clientData && clientData._id) {
                                setCustomerId(clientData._id);
                            } else {
                                props.form.setFieldsValue({
                                    name: "",
                                    email: "",
                                    age: "",
                                    city: "",
                                    mobile: "",
                                    address: "",
                                    state: "",
                                    pincode: "",
                                    gender: "",
                                    paidAmount: ""
                                });
                                setSlipRecord([]);
                                setUhId("");
                                setCustomerId("");
                            }
                        }}
                    />
                </div>
                <div className="col-1 sale-view">
                    {customerId && (
                        <>
                            <button
                                className="btn btn-info btn-sm"
                                style={{height: "35px"}}
                                onClick={() => {
                                    setSaleIsModalOpen(true);
                                }}
                            >
                                Prev.Rec.
                            </button>
                            {isSaleModalOpen ? (
                                <SaleDetailModal
                                    className={"mt-4"}
                                    visible={isSaleModalOpen}
                                    onClose={() => {
                                        setSaleIsModalOpen(false);
                                    }}
                                    customerId={customerId}
                                    customerRecord={customerRecord}
                                />
                            ) : null}
                        </>
                    )}
                </div>

                {customerId && (
                    <>
                        {slipRecord && slipRecord.length > 0 && (
                            <InputBox className={"col-4 methodss"}>
                                <label className="previous-record">Previous Visit Record</label>
                                < table className="table table-bordered previous-visit-record">
                                    <thead>
                                    <tr>
                                        <th scope="col">Type</th>
                                        <th scope="col">Doctor Name</th>
                                        <th scope="col">Start Date</th>
                                        <th scope="col">Expiry Date</th>
                                        <th scope="col">Count</th>
                                        <th scope="col">View</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {slipRecord.map((item, index) => (
                                        <tr key={index}>
                                            <td className={"empty_btn m-3 "}>
                                                {item?.type ? item.type.charAt(0) : ""}
                                            </td>
                                            <td>{item?.doctorId?.name}</td>
                                            <td>
                                                {moment(item.startDate).format("DD/MM/YYYY") || ""}
                                            </td>
                                            <td>{moment(item.expiryDate).format("DD/MM/YYYY")}</td>
                                            <td>
                                                {item.visitCount}
                                                {item.type === "Slip" && item.visitCount < 2 && (
                                                    <Popconfirm
                                                        title={"Are you sure you want to generate visit?"}
                                                        okText="Yes"
                                                        cancelText="No"
                                                        onConfirm={() => {
                                                            generateSlip(item.doctorId);
                                                        }}
                                                    >
                                                        <button className="btn btn-info btn-sm m-2">
                                                            Create Visit
                                                        </button>
                                                    </Popconfirm>
                                                )}
                                                {item.type === "Membership" && (
                                                    <Popconfirm
                                                        title={"Are you sure you want to generate visit?"}
                                                        okText="Yes"
                                                        cancelText="No"
                                                        onConfirm={() => {
                                                            generateSlip(item.doctorId);
                                                        }}
                                                    >
                                                        <button className="btn btn-info btn-sm m-2">
                                                            Create Visit
                                                        </button>
                                                    </Popconfirm>
                                                )}
                                            </td>
                                            <td>
                                                {customerId && (
                                                    <>
                                                        <a
                                                            className={"empty_btn design"}
                                                            onClick={() => {
                                                                setIsModalOpen(true);
                                                            }}
                                                        >
                                                            <i className={"fa fa-eye far"}/>
                                                        </a>
                                                        {isModalOpen ? (
                                                            <CustomerDetails
                                                                className={"mt-4"}
                                                                visible={isModalOpen}
                                                                onClose={() => {
                                                                    setIsModalOpen(false);
                                                                }}
                                                                customerId={customerId}
                                                                customerRecord={customerRecord}
                                                            />
                                                        ) : null}
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </InputBox>
                        )}
                    </>
                )}
            </div>

            <Form onSubmit={handleSubmit}>
                <div className="form-elements-wrapper">
                    <div className={"row"}>


                        {inputTypes.upperFields.map((item, key) => {
                            return !item.hidden ? (
                                <div
                                    className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                                    key={key}
                                >
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            ) : null;
                        })}
                        <div className={"col-md-3 mb-3"}>
                            <ChooseSymptom
                                symptomId={symptomId}
                                callback={(clientData) => {
                                    if (clientData && clientData._id) {
                                        setSymptomIdVal(clientData._id);
                                    }
                                }}
                            />
                        </div>
                        {customerId && (
                            <>
                                <div className={"col-3"}>
                                    <InputBox title={"UHID"}>
                                        <input
                                            key="uhid"
                                            type="number"
                                            label="UH Id *"
                                            span="col-md-3"
                                            placeholder="Uh Id"
                                            value={uhid}
                                            className="form-control"
                                            disabled
                                        />
                                    </InputBox>
                                </div>
                            </>
                        )}

                    </div>

                    {getFieldValue("patientVisit") !== "Free" && (
                        <div className={"col-md-12 mt-4"}>
                            <ItemListComponent
                                ProductReport={ProductReport}
                                updateProductReport={updateProductReport}
                                statesAreEqual={true}
                                companyState={companyState}
                                stateOfSupply={stateOfSupply}
                            />
                        </div>
                    )}
                </div>
                {getFieldValue("patientVisit") !== "Free" && (
                    <div className={"row"}>
                        {inputTypes.lowerFields.map((item, key) => {
                            return !item.hidden ? (
                                <div
                                    className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                                    key={key}
                                >
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            ) : null;
                        })}
                    </div>
                )}

                {getFieldValue('paidAmount') > 0 ?
                    <Card title={"Payments"} size={"small"}>
                        <div className="d-flex align-items-end">
                            <div>
                                {formData.map((data, index) => (
                                    <div className={"item-flex mt-2"} key={data.key}>
                                        <InputBox title={"Banks"}>
                                            <select
                                                style={{height: "45px"}}
                                                className="form-control"
                                                value={data.bank}
                                                onChange={(e) =>
                                                    handleInputChange(index, "bankId", e.target.value)
                                                }
                                                required="true"
                                            >
                                                <option value="">Select Bank</option>
                                                {banks.map((bank) => (
                                                    <option key={bank._id} value={bank._id}>
                                                        {bank.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </InputBox>

                                        <InputBox title={"Payment Type"}>
                                            <select
                                                style={{
                                                    height: "45px",
                                                }}
                                                className="form-control"
                                                value={data.paymentType}
                                                required
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        index,
                                                        "paymentType",
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <option value="">Select Payment Type</option>
                                                {PaymentTypes.map((PaymentTypes) => (
                                                    <option>{PaymentTypes.name}</option>
                                                ))}
                                            </select>
                                        </InputBox>

                                        <InputBox title={"Amount"}>
                                            <input
                                                style={{
                                                    height: "45px",
                                                }}
                                                className={"form-control"}
                                                type="text"
                                                value={data.amount}
                                                onChange={(e) =>
                                                    handleInputChange(index, "amount", e.target.value)
                                                }
                                                placeholder="Amount"
                                            />
                                        </InputBox>

                                        <InputBox title={"Transaction Details"}>
                                            <input
                                                style={{
                                                    height: "45px",
                                                }}
                                                className={"form-control"}
                                                value={data.transactionDetails}
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        index,
                                                        "transactionDetails",
                                                        e.target.value
                                                    )
                                                }
                                                placeholder="Transaction Details"
                                            />
                                        </InputBox>
                                        <InputBox className="deleteBtn">
                                            <div key={index} className={"item-flex mt-3"}>
                                                {index > 0 &&
                                                showDeleteButtons[index] && ( // Condition added to not show delete button for zero index
                                                    <a
                                                        className="empty_btn"
                                                        onClick={() => handleDelete(index)}
                                                    >
                                                        <i className="fa fa-trash-alt"></i>
                                                    </a>
                                                )}
                                            </div>
                                        </InputBox>
                                    </div>
                                ))}
                            </div>
                            <div>
                                <InputBox className={"pb-2"}>
                                    <a className="addBtn  btn-link" onClick={handleAddField}>
                                        Add More
                                    </a>
                                </InputBox>
                            </div>
                        </div>
                    </Card> : null}

                {getFieldValue("slipType") && (
                    <div className="border border-dark p-3 mb-4">
                        <div className="form-elements-wrapper mt-4">
                            <div className="row">
                                <div className="col-md-3">
                                    <strong>Start Date: </strong>{" "}
                                    {dates.startDate && dates.startDate.format("DD/MM/YYYY")}
                                </div>
                                <div className="col-md-3">
                                    <strong>Expiry Date: </strong>{" "}
                                    {dates.expiryDate && dates.expiryDate.format("DD/MM/YYYY")}
                                </div>
                                <div className="col-md-3">
                                    <strong>Validity: </strong> {dates.validity}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className={'mt-2'}>
                    <button type="submit" className="btn btn-gradient-info me-2 mt">
                        Submit
                    </button>
                    <HyperLink className=" ms-4 btn btn-danger" link={"/patient"}>
                        Cancel
                    </HyperLink>
                </div>
            </Form>
        </>
    );
};

export default Form.create()(AddPatient);
