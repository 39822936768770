import React, { useRef, useState, useEffect } from "react";
import { InputBox, Form, Table,Tooltip } from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import { fetchCustomer } from "./actions";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { getToken } from "../../request";
import axios from "axios";
import { getTransaction } from "./api";
import TransactionComp from "../../components/transactionComp";
import {fetchSaleCategory} from "../category/actions";
const PatientList = (props) => {
  const navigate = useNavigate();
  let tableRef = useRef();

  const [name1, setName] = useState();
  const [mobile, setMobile] = useState();
  const [email, setEmail] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [address, setAddress] = useState();
  const [customerId, setCustomerId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [report, setReport] = useState([]);
  const [saleCategory, setSaleCategory] = useState([]);
  const [saleCategoryId, setSaleCategoryId] = useState("");


  const handleS = async (e) => {
    e.preventDefault();
    tableRef.current.reload();
  };

  const fetchSaleFrom = async () => {
    const {data} = await fetchSaleCategory({});
    setSaleCategory(data);
  };

  const [count, setCount] = useState(0);
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params.type = "patient";
      params.regExFilters = ["name", "email", "mobile", "state", "city"];
      if (name1) {
        params.name = name1;
      }
      if (mobile) {
        params.mobile = mobile;
      }
      if (email) {
        params.email = email;
      }
      if (state) {
        params.state = state;
      }
      if (city) {
        params.city = city;
      }

        if(saleCategoryId)
          params.saleCategory=saleCategoryId;
      // params.mobile = "91";
      const data = await fetchCustomer({ ...params });

      const countOfPatients = data.data.filter(
        (item) => item.type === "patient"
      ).length;
      setCount(countOfPatients);

      resolve(data);
    });
  };

  const fetchTransaction = async (dispach) => {
    try {
      let config = {
        params: { customerId },
        ...(await getToken()),
      };

      const response = await axios.get(getTransaction(customerId), config);
      setReport(response.data.data);
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  useEffect(() => {
    fetchTransaction();
  }, [customerId]);
  
  useEffect(() => {
    if (isModalOpen) {
      fetchTransaction();
    }
  }, [isModalOpen]);
  const columns = [
    {
      title: "Sr. No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "Mobile No",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Source Medium",
      dataIndex: "sourceMedium",
      key: "sourceMedium",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
          <>
            {
              record.addedOn ? (
                  moment(record.addedOn).format("DD/MM/YYYY")
                  ) :
                  moment(record.createdAt).format("DD/MM/YYYY")
            }
          </>
      ),
    },

    {
      title: "UHID",
      dataIndex: "uhid",
      key: "uhid",
    },
    {
      title: "Username",
      dataIndex: "saleCategory",
      key: "saleCategory",
      render: (text, record) => (
          <>
            {text && text.name ? text.name : null}
          </>
      ),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (text, record) => (text? (text.toFixed(2)):null),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (val, record) => {
        return (
          <>
            <Tooltip title={"View"}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className={"empty_btn m-2"}
                onClick={() => {
                  setIsModalOpen(true);
                  setCustomerId(record._id);
                }}
              >
                <i className={"fa fa-eye far"}/>
              </a>
            </Tooltip>
          </>
        );
      }
    }
  ];

  useEffect(()=>{
    fetchSaleFrom();
  },[])
  const handleClear = () => {
  
    setName("");
    setMobile("");
    setEmail("");
    setState("");
    setCity("");
    setAddress("");
    setSaleCategoryId("");
    if(tableRef.current.reload) {
      tableRef.current.reload()
    }
   
  };
  
 
  return (
    <PageHeader
      title={`Patients (${count})`}
      extraLink={[
        {
          name: "Add Patients",
          link: "/add-patient",
        },
      ]}
    >
      <Form>
        <div class="card">
          <div class="card-body">
            <div class="form-elements-wrapper">
              <div class="row">
                <div class="col-md-2 mb-2 pe-0">
                  <InputBox>
                    <input
                      type="text"
                      className={"form-control search-filters"}
                      placeholder="Search by name"
                      value={name1}
                      onChange={({ target: { value } }) => setName(value)}
                      required
                    />
                  </InputBox>
                </div>
                <div class="col-md-2 mb-2 pe-0">
                  <InputBox>
                    <input
                      type="text"
                      className={"form-control search-filters"}
                      placeholder="Search by mobile"
                      value={mobile}
                      onChange={({ target: { value } }) => setMobile(value)}
                      required
                    />
                  </InputBox>
                </div>
                <div class="col-md-2 mb-2 pe-0">
                  <InputBox>
                    <input
                      type="text"
                      className={"form-control search-filters"}
                      placeholder="Search by email"
                      value={email}
                      onChange={({ target: { value } }) => setEmail(value)}
                      required
                    />
                  </InputBox>
                </div>
                <div class="col-md-2 mb-2 pe-0">
                  <InputBox>
                    <input
                      type="text"
                      className={"form-control search-filters"}
                      placeholder="Search by state"
                      value={state}
                      onChange={({ target: { value } }) => setState(value)}
                      required
                    />
                  </InputBox>
                </div>
                <div class="col-md-2 mb-2 pe-0">
                  <InputBox>
                    <input
                      type="text"
                      className={"form-control search-filters"}
                      placeholder="Search by City"
                      value={city}
                      onChange={({ target: { value } }) => setCity(value)}
                      required
                    />
                  </InputBox>
                </div>
                <div class="col-md-2 mb-2 pe-0">
                  <InputBox>
                    <input
                      type="text"
                      className={"form-control search-filters"}
                      placeholder="Search by Address"
                      value={address}
                      onChange={({ target: { value } }) => setAddress(value)}
                      required
                    />
                  </InputBox>
                </div>
                <div className="col-md-2 mb-2 pe-0">
                  <InputBox>
                    <select
                        className="form-control search-filters"
                        onChange={({ target: { value } }) => {setSaleCategoryId(value); console.log(value)}}
                        value={saleCategoryId}
                        required
                        style={{height:"46px" , textAlign:"left" , paddingLeft:"21px" , marginTop:"10px" ,fontSize:'14px'}}
                    >
                      <option value="" disabled selected>
                       Select User Name
                      </option>
                      {saleCategory.map((category) => (
                          <option key={category._id} value={category._id}>
                            {category.name}
                          </option>
                      ))}
                    </select>
                  </InputBox>
                </div>
              </div>
            </div>
            <div className="mb-3 search-buttons">
              <div className="search-wrap pe-2">
                <button className="btn btn-info" onClick={handleS}>
                  Search
                </button>
              </div>
              <div className="search-wrap">
                <button className="btn btn-secondary" onClick={handleClear}>
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <Table
                  apiRequest={(props) => apiRequest(props)}
                  columns={columns}
                  ref={tableRef}
                  position={'top'}
                />
              </div>
            </div>
          </div>
        </div>
      </Form>
      {isModalOpen ? (
        <TransactionComp
          visible={isModalOpen}
          Report={report}
          onClose={() => {
            setIsModalOpen(false);
             setReport([]);
          }}
        />
      ) : null}
    </PageHeader>
  );
};

export default PatientList;
