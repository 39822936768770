import {
  getSaleUrl,
  addSaleUrl,
  editSaleUrl,
  getCustomerDataUrl,
  getbatchRecordByProductIdUrl,
  invoiceUpdateUrl,
  addCreditNoteUrl,
  getCreditNoteUrl, getCreditNoteCsvUrl,
} from "../api";
import { customAxios as axios, getToken } from "../../../request";
import { hideLoader, showLoader } from "../../../actions/loader";
import notification from "../../../components/Elements/Notification";
import {getSaleCsvUrl} from "../../sales/api";

export const fetchCustomer = async (valData) => {
  let config = {
    params: { ...valData },
    ...getToken(),
  };
  const { data } = await axios.get(getCustomerDataUrl(),  config);

  return data;
};

export const fetchBatchByProductId = async (productId) => {
  let config = {
    params: { productId },
  };
  const { data } = await axios.get(getbatchRecordByProductIdUrl(), config);
  return data;
};

export const fetchCreditNote = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  const { data } = await axios.get(getCreditNoteUrl(), config);
  return data;
};
export const fetchCreditNoteByCustomerId = async (valData) => {
  let config = {
    params: { customerId:valData },
    ...(await getToken()),
  };
  const { data } = await axios.get(getCreditNoteUrl(), config);
  return data;
};

export const invoiceUpdateFxn = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  const { data } = await axios.get(invoiceUpdateUrl(), config);
  return data;
};

export const addCreditNoteFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  const { data } = await axios.post(addCreditNoteUrl(), valData, getToken());
  dispatch(hideLoader());
  if (!data.error) {
  } else {
    notification.error(data.message || "Error");
  }
  return data;
};

export const editSaleFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  const { data } = await axios.post(editSaleUrl(), valData, getToken());
  dispatch(hideLoader());
  if (!data.error) {
  } else {
    notification.error(data.message || "Error");
  }
  return data;
};
export const fetchCreditNoteForCsv = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
    responseType: 'blob',  // Set the response type to blob
  };
  const { data } = await axios.get(getCreditNoteCsvUrl(), config);
  return data;
};
