import React, { useState, useEffect } from "react";
import {
    Modal,
    Form,
} from "../../../components/Elements/appUtils";
import { getProductReport } from "../../purchase/api";
import { showLoader, hideLoader } from "../../../actions/loader";
import moment from "moment";
import { getSaleByCustomerId } from "./api";
import { getToken } from "../../../request";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Tabs } from "../../../components/Elements/appUtils";

let { TabPane } = Tabs;
const SaleDetailsModal = (props) => {
    let {
        customerId,
        visible,
        onClose,
    } = props;

    const dispatch = useDispatch();
    const [sales, setSales] = useState([]);
    const [saleReports, setSaleReports] = useState({});

    const fetchSaleByCustomer = async () => {
        try {
            let config = {
                params: { customerId },
                ...(await getToken()),
            };

            dispatch(showLoader());

            const response = await axios.get(getSaleByCustomerId(), config);
            setSales(response.data.data);
            dispatch(hideLoader());
        } catch (error) {
            console.error("Error getting the data:", error);
        }
    };

    const fetchReportBySale = async (saleId, index) => {
        try {
            let config = {
                params: { salesId: saleId },
                ...(await getToken()),
            };
            const response = await axios.get(getProductReport(), config);
            const newReportData = response.data.data;

            // Check if the toggle is already open
            const isOpen = saleReports[saleId] && saleReports[saleId].length > 0;

            // Update the saleReports state
            setSaleReports((prevReports) => ({
                ...prevReports,
                [saleId]: isOpen ? [] : newReportData, // If already open, close, otherwise fetch new data
            }));
        } catch (error) {
            console.error("Error getting the data:", error);
        }
    };

    useEffect(() => {
        if (visible) {
            fetchSaleByCustomer();
        }
    }, [visible]);

    const printFxn = () => {
        const printContents = document.getElementById("printable").innerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
    };

    return (
        <>
            <Modal
                title={
                    <div className={'d-flex justify-content-between'}>
                        <div>Sale Details</div>
                        <div>
                            <button className="btn btn-link" onClick={printFxn}>
                                Print
                            </button>
                        </div>
                    </div>
                }
                visible={visible}
                onClose={onClose}
                width={"95%"}
            >
                <div>
                    <Form>
                        <div className={"row"}>
                            <div
                                className={"col-md-12"}
                                 id="printable"
                            >

                                <Tabs defaultActiveKey="1" onChange={() => {}}>
                                    <TabPane tab={"Sale Details"} key="1">
                                        {sales?.map((sale, index) => (
                                            <div key={index}>
                                                <div
                                                    className="faq-question"
                                                    style={{
                                                        border: "1px solid #ccc",
                                                        padding: "10px",
                                                        marginBottom: "10px",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <div className="row">
                                                            <div
                                                                onClick={() =>
                                                                    fetchReportBySale(sale._id, index)
                                                                }
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                <h4 style={{ margin: 0 }}>
                                                                    Invoice Number: {sale.invoiceNumber}
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;Date:{" "}
                                                                    {moment(sale.salesDate).format("DD/MM/YYYY")}
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {saleReports[sale._id] && saleReports[sale._id].length > 0 && (
                                                    <div
                                                        className="table-wrapper"
                                                        style={{
                                                            maxHeight: "200px",
                                                            overflowX: "auto",
                                                            overflowY: "auto",
                                                        }}
                                                    >
                                                        <table className="table table-bordered mt-1 mb-2">
                                                            <thead>
                                                            <tr>
                                                                <td>Sr no.</td>
                                                                <td>Product Name</td>
                                                                <td>Report Type</td>
                                                                <td>Mfg Date</td>
                                                                <td>Exp Date</td>
                                                                <td>Batch</td>
                                                                <td>Qty.</td>
                                                                <td>Sale Price</td>
                                                                <td>Price</td>
                                                                <td>Dis Amt.</td>
                                                                <td>Taxable Amt.</td>
                                                                <td>CGST Amt.</td>
                                                                <td>SGST Amt.</td>
                                                                <td>Net Amt.</td>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {saleReports[sale._id].map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item.productName}</td>
                                                                    <td>{item.reportType}</td>
                                                                    <td>{item.manufacturingDate}</td>
                                                                    <td>{item.expiryDate}</td>
                                                                    <td>{item.batchNo}</td>
                                                                    <td>{item.quantity}</td>
                                                                    <td>Rs. {item.salePrice}</td>
                                                                    <td>Rs. {item.price}</td>
                                                                    <td>
                                                                        Rs. {item.discountAmount} (
                                                                        {item.discount}%)
                                                                    </td>
                                                                    <td>Rs. {item.taxableAmount}</td>
                                                                    <td>Rs. {item.cgstAmount}</td>
                                                                    <td>Rs. {item.sgstAmount}</td>
                                                                    <td>Rs. {item.netAmount}</td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>
        </>
    );
};
export default Form.create()(SaleDetailsModal);
