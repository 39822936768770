import _ from "lodash";
import countryJson from "../../jsons/countries.json";
import groupTypeJson from "../../jsons/groupType.json";
import stateJson from "../../jsons/states.json";
import indianStateJson from "../../jsons/indianState.json";
import indianCityJson from "../../jsons/indiaCities.json";
import slipType from "../../jsons/slipType.json";
import pType from "../../jsons/patientType.json";
import vType from "../../jsons/visitType.json";
import PaymentType from "../../jsons/paymentType.json";
import patientCategory from "../../jsons/patientCategory.json";
import referralCode from "../../jsons/referralCode.json";
import shipThrough from "../../jsons/shipThrough.json";
import expenseTypes from "../../jsons/expenseTypes.json";

export const Countries = [...countryJson];
export const States = [...stateJson];
export const IndianStates = [...indianStateJson];
export const shipthrough = [...shipThrough];
export const IndianCities = [...indianCityJson];
export const patientType = [...pType];
export const visitType = [...vType];
export const SlipType = [...slipType];
export const groupType = [...groupTypeJson];
export const PaymentTypes = [...PaymentType];
export const PatientCategory = [...patientCategory];
export const ReferralCode = [...referralCode];
export const ExpenseTypes = [...expenseTypes];
export const gstTypeList = [
    {name: "Unregistered Consumer", value: "Unregistered"},
    {name: "Registered Consumer", value: "Registered"},
];
export const gender = [
    {name: "Male", value: "male"},
    {name: "Female", value: "female"},
];

export const yesNo = [
    {name: "Yes", value: true},
    {name: "No", value: false},
];

export const testReport = [
    {
        "id": "1",
        "name": "Trichoscopy Test",

    },
    {
        "id": "2",
        "name": "Blood Test",

    },

];

export const diagnosis = [
    {
        "id": "1",
        "name": "Telogen Effluvium"
    },
    {
        "id": "2",
        "name": "Miniaturization"
    },
    {
        "id": "3",
        "name": "Premature Graying"
    },
    {
        "id": "4",
        "name": "Alopecia Androgenetica"
    },
    {
        "id": "5",
        "name": "Traction Alopecia"
    },
    {
        "id": "6",
        "name": "Pityriasis Capitis"
    },
    {
        "id": "7",
        "name": "Seborrheic Dermatitis"
    },
    {
        "id": "8",
        "name": "Tinea Capitis"
    },
    {
        "id": "9",
        "name": "Folliculitis"
    },
    {
        "id": "10",
        "name": "Alopecia Areata"
    },
    {
        "id": "11",
        "name": "Alopecia Totalis"
    },
    {
        "id": "12",
        "name": "Alopecia Universalis"
    },
    {
        "id": "13",
        "name": "Cicatricial Alopecia"
    },
    {
        "id": "14",
        "name": "Trichotillomania"
    },
    {
        "id": "15",
        "name": "Bipolar Hair"
    },
    {
        "id": "16",
        "name": "Pediculosis"
    },
    {
        "id": "17",
        "name": "Alopecia Barbae"
    },
    {
        "id": "18",
        "name": "Hirsutism"
    },
    {
        "id": "19",
        "name": "Hypertrichosis"
    }
];

export const complain = [
    {
        "id": "1",
        "name": "Hair fall"
    },
    {
        "id": "2",
        "name": "Hair loss"
    },
    {
        "id": "3",
        "name": "Hair thinning"
    },
    {
        "id": "4",
        "name": "Receding hair line"
    },
    {
        "id": "5",
        "name": "Baldness"
    },
    {
        "id": "6",
        "name": "Scalp itchiness"
    },
    {
        "id": "7",
        "name": "Excessive oiliness"
    },
    {
        "id": "8",
        "name": "Dryness of the scalp"
    },
    {
        "id": "9",
        "name": "Changes in hair texture"
    },
    {
        "id": "10",
        "name": "Oily flaky dandruff"
    },
    {
        "id": "11",
        "name": "Hair breakage"
    },
    {
        "id": "12",
        "name": "Scalp pain"
    },
    {
        "id": "13",
        "name": "Frizzy hair"
    },
    {
        "id": "14",
        "name": "Unwanted hair"
    },
    {
        "id": "15",
        "name": "Allergic reactions to hair products"
    },
    {
        "id": "16",
        "name": "Scalp infections"
    },
    {
        "id": "17",
        "name": "Hair pulling"
    },
    {
        "id": "18",
        "name": "Hair damage from heat styling"
    },
    {
        "id": "19",
        "name": "Hair damage from chemical treatments"
    },
    {
        "id": "20",
        "name": "Premature graying of hair"
    },
    {
        "id": "21",
        "name": "Hair tangling"
    },
    {
        "id": "22",
        "name": "Hair matting"
    },
    {
        "id": "23",
        "name": "Scalp sunburn"
    },
    {
        "id": "24",
        "name": "Hair loss after childbirth"
    },
    {
        "id": "25",
        "name": "Scalp psoriasis"
    },
    {
        "id": "26",
        "name": "Excessive sweating from the scalp"
    },
    {
        "id": "27",
        "name": "Patchy Hair Loss"
    }
];


export const branchType = [
    {
        name: "Head Branch",
        key: "headBranch",
    },
    {
        name: "Branch",
        key: "branch",
    },
];

export const UsersType = [
    {
        name: "Purchase User",
        key: "purchaseUser",
    },
    {
        name: "Transfer User",
        key: "transferUser",
    },
];
export const generateSaleProduct = [
    {
        name: "SaleProduct",
        _id: "1",
        price: "5000",
        hsnCode: "34",
    },
];

export const getStateByCountry = (countryName) => {
    let findCountry = _.find(countryJson, (item) => {
        return item.name === countryName;
    });
    if (findCountry) {
        let stateList = _.filter(stateJson, (item) => {
            return item.country_id === findCountry.id;
        });
        return stateList;
    } else {
        return [];
    }
};

export const getCityByState = (stateName) => {
    let findState = _.find(stateJson, (item) => {
        return item.name === stateName;
    });
    if (findState) {
        let cityList = _.filter(indianCityJson, (item) => {
            return item.state_id === findState.id;
        });
        return cityList;
    } else {
        return [];
    }
};
export const monthYearPicker = (data) => {
    // data = data.toString()
    data = data.replace("/", "").replace(/[^\d]/, "");
    let month = data.substring(0, 2);
    let year = data.substring(2, 6);
    let date = "";
    if (month) {
        date = date + month;
    }
    if (year) {
        year = "/" + year;
    }
    return `${date}${year}`;
};

export const arraySorter = (data) => {
    const sortedProducts = [...data].sort((a, b) => {
        const firstWordA = a.productName.split(" ")[0].toUpperCase();
        const firstWordB = b.productName.split(" ")[0].toUpperCase();

        if (firstWordA < firstWordB) {
            return -1;
        }
        if (firstWordA > firstWordB) {
            return 1;
        }
        return 0;
    });
    return sortedProducts;
};


export let expenseCategory = [
    "CAPITAL ACCOUNT",
    "CURRENT ASSETS",
    "CURRENT LIABILITIES",
    "DUTIES AND TAXES",
    "EXPENSE (DIRECT)",
    "EXPENSES (INDIRECT)",
    "FIXED ASSETS",
    "INCOME ( INDIRECT)",
    "INCOME (DIRECT )",
    "INVESTMENT",
    "LOAN & ADVANCES",
    "LOAN ( LIABILITIES)",
    "PRE OPERATIVE EXPENSES",
    "PROFIT & LOSS",
    // "PROVISION/ EXPENSES PAYABLE",
    "RESERVES & SURPLUS",
    "REVENUE ACCOUNT",
    "SECURED LOAN",
    "SECURITIES & DEPOSITS (ASSET)",
    "STOCK IN HAND",
    "SUSPENSE ACCOUNT",
    "UNSECURED LOAN"
]

export let ExpenseCategory2 = [
    "EXPENSE (DIRECT)",
    "EXPENSES (INDIRECT)",
]

export const arraySorterByParticularField = (data, fieldName) => {
    return [...data].sort((a, b) => {
        const firstWordA = a[fieldName].split(" ")[0].toUpperCase();
        const firstWordB = b[fieldName].split(" ")[0].toUpperCase();
        if (firstWordA < firstWordB) {
            return -1;
        }
        if (firstWordA > firstWordB) {
            return 1;
        }
        return 0;
    });
};


