import {
    BrowserRouter as Router,
    Routes,
    Route, Navigate,
} from "react-router-dom";

import AppLayout from "../src/Layout/AppLayout/layout"
import AuthLayout from "../src/Layout/AuthLayout/layout"
import Login from './pages/login/login';
import {history} from "./reducers/store";
import {menuData} from "./menu";
import EditCompany from "./pages/company/editCompany";
import PrescriptionDetail from "./pages/doctor/prescriptionDetail";
import PrintLayout from "./Layout/AppLayout/printlayout";
import SaleLayout from "./Layout/AppLayout/saleLayout";
import Check1 from "./pages/sales/check1";
import PasswordReset from "./pages/passwordreset/passwordreset";
import ForgotPassword from './pages/forgotPassword/forgotPassword';

const RouteList = (props) => {
    let {currentUser} = props;
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    return (
        <Router history={history}>
            <Routes>
                <Route path={"/"} element={<AppLayout {...props}/>}>
                    {menuData.map((item) => {
                        let {component: Component} = item;
                        if (!item.subMenu && user && item.authority) {
                            return (
                                item.authority.includes(user.userType) ?
                                    <Route exact path={item.path} key={item.key} element={<Component/>}/> : null
                            )
                        }
                        
                    })}

                    {menuData.map((item) => {
                        if (item.subMenu && item.subMenu.length && user && item.authority) {
                            return item.subMenu.map((child, k) => {
                                let {component: Component} = child;
                                return (
                                    item.authority.includes(user.userType) ?
                                        <Route exact path={child.path} key={child.key} element={<Component/>}/> : null
                                )
                            })

                        }
                    })}

                </Route>
             
                  
                
                <Route element={<AuthLayout/>}>
                    <Route exact path="/login" element={<Login/>}/>
                </Route>
                <Route element={<AuthLayout/>}>
                    <Route exact path="/forgot-password" element={<ForgotPassword/>}/>
                </Route>
                <Route element={<AuthLayout/>}>
                    <Route exact path="/password-reset" element={<PasswordReset/>}/>
                </Route>
                <Route element={<PrintLayout/>}>
                    <Route exact path="/prescription-detail" element={<PrescriptionDetail/>}/>
                </Route>
                <Route element={<SaleLayout/>}>
                    <Route exact path="/print-sale" element={<Check1/>}/>
                </Route>
                <Route path="*" element={<Navigate replace to="/login"/>}/>
               
            </Routes>
        </Router>
    )
}
export default RouteList
