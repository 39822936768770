import React, {useState, useEffect} from "react";
import axios from "axios";
import {
    Form,
    notification,
    HyperLink,
    fixed2Digit,
    InputBox,
    Card,
} from "../../components/Elements/appUtils";
import {useDispatch} from "react-redux";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {
    States,
    PaymentTypes,
    IndianStates, shipthrough,
} from "../../components/_utils/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import _ from "lodash";
import ItemListComponent from "./ItemListComponent";
import {editSaleFxn, fetchSale} from "./actions";
import {getItemLocalstorage} from "../../components/_utils/_utils";
import {useNavigate} from "react-router-dom";
import {fetchBank} from "../customer/actions";
import moment from "moment";
import {fetchSaleCategory} from "../category/actions";
import {getProductReport} from "../purchase/api";
import {getToken} from "../../request";

function EditSales(props) {
    const initialBankState = [
        {
            bankId: "",
            paymentType: "",
            amount: "",
            transactionDetails: "",
            name: "",
        }
    ]
    const [formData, setFormData] = useState(initialBankState);
    const [showDeleteButtons, setShowDeleteButtons] = useState([false]);
    const [clientData, setClientData] = useState([]);

    const getClient = async (params = {}) => {
        params.results = 50;
        params.count = 50;
        // params.page = 1
        params.customerGroupName = ['EXPENSES (INDIRECT)','EXPENSE (DIRECT)']
        const {data} = await fetchBank({...params, regExFilters: ["name",]});
        if(data && data.length){
            setClientData(data);
        }else{
            setClientData([]);
        }
    };
    useEffect(() => {
        getClient()
    }, []);


    const handleInputChange = (index, field, value) => {
        const newData = [...formData];
        newData[index][field] = value;
        console.log(newData, "newData");
        setFormData(newData);
        setShowDeleteButtons((prevButtons) =>
            prevButtons.map((btn, idx) => (idx === index ? true : btn))
        );
    };
    const handleDelete = (index) => {
        const newFormData = [...formData];
        newFormData.splice(index, 1);
        setFormData(newFormData);

        setShowDeleteButtons((prevButtons) => {
            const updatedButtons = [...prevButtons];
            updatedButtons[index] = true; // Set true for deleted index
            return updatedButtons;
        });
    };

    const handleAddField = () => {
        const sumAmount = formData.reduce(
            (sum, item) => sum + parseFloat(item.amount) || 0,
            0
        );
        if (sumAmount > getFieldValue("paidAmount")) {
            notification.warning({
                message: "Amount Cannot Be Greater Than Recieving Amount",
            });
            return;
        }
        if (sumAmount == getFieldValue("paidAmount")) {
            notification.warning({
                message: "Enough Amount Added, Cannot Add More Payment Method",
            });
            return;
        }

        const lastData = formData[formData.length - 1];
        if (!lastData?.bankId) {
            notification.warning({message: "Please Choose Bank"});
            return;
        }
        if (!lastData?.paymentType) {
            notification.warning({message: "Please Choose Payment Type"});
            return;
        }
        if (!lastData?.amount) {
            notification.warning({message: "Please Enter Amount"});
            return;
        }

        setFormData([
            ...formData,
            {
                bankId: "",
                paymentType: "",
                amount: "",
                transactionDetails: "",
                name: "",
            },
        ]);

        setShowDeleteButtons([...showDeleteButtons, true]);
    };

    useEffect(() => {
        if (formData.length > 1) {
            setShowDeleteButtons(Array(formData.length).fill(true));
            setShowDeleteButtons((prevButtons) => {
                prevButtons[0] = false;
                return prevButtons;
            });
        }
    }, [formData]);
    const companyStateCode = getItemLocalstorage("user")["stateCode"];

    const {getFieldValue} = props.form;
    const navigate = useNavigate();
    let dispatch = useDispatch();
    let [customerId, setCustomerId] = useState("");
    let [customerName, setCustomerName] = useState("");
    let [customerState, setCustomerState] = useState("");
    let [inoviceNumber, setInoviceNumber] = useState("");

    const paidToUserId = getFieldValue("paidToUserId");
    const [banks, setBanks] = useState([]);

    const [saleCategory, setSaleCategory] = useState([]);
    const [ProductReport, setProductReport] = useState([]);

    const updateProductReport = (newProductReport) => {
        setProductReport(newProductReport);
    };

    const [saleData, setSaleData] = useState();
    const [bankName, setBankName] = useState("");
    const [saleId, setSaleId] = useState(null);
    const [includeBalance, setIncludeBalance] = useState(true);

    useEffect(() => {
        if (saleId) {
            getSale();
            fetchProductReport();
        }
    }, [saleId]);

    const getSale = async () => {
        return new Promise(async (resolve) => {
            try {
                let params = {
                    _id: saleId,
                };
                const data = await fetchSale({...params});

                const ExtractedData = data.data[0];
                console.log(ExtractedData, "fetchsale");

                setSaleData(ExtractedData);
                setCustomerId(ExtractedData.customerId._id);
                setCustomerName(ExtractedData.customerId.name);
                setCustomerState(ExtractedData.customerId.state);
                setInoviceNumber(ExtractedData.invoiceNumber);
                if (ExtractedData.paidToUser.length != 0) {
                    setFormData(ExtractedData.paidToUser);
                }
                props.form.setFieldsValue({
                    salesDate: moment(ExtractedData.salesDate),
                    saleCategory: ExtractedData.saleCategory,
                    stateOfSupply: ExtractedData.stateOfSupply
                        ? parseInt(ExtractedData.stateOfSupply)
                        : null,
                    otherState:ExtractedData.otherState,
                    paidAmount: ExtractedData.paidAmount,
                    invoiceNumber: ExtractedData.invoiceNumber,
                    orderId: ExtractedData.orderId,
                    shipThrough: ExtractedData.shipThrough,
                    trackingDetails: ExtractedData.trackingDetails,
                    cutOffAmount: ExtractedData.cutOffAmount,
                    customDiscount: ExtractedData.customDiscount,
                    client: ExtractedData.client,
                });
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };

    const fetchProductReport = async () => {
        try {
            let config = {
                params: {salesId: saleId},
                ...getToken(),
            };
            const response = await axios.get(getProductReport(), config);
            console.log(response, "fawefwqf");
            setProductReport(response.data.data);
        } catch (error) {
            console.error("Error getting the data:", error);
        }
    };

    let totalAmount = 0;
    let netAmount = 0;
    let quantity = 0;
    let balance = 0;

    const calNetAmount = () => {
        if (ProductReport.length == 0) {
            props.form.setFieldsValue({
                totalAmount: 0,
                finalAmount : 0,
                balance: 0,
                quantity: 0,
                creditNoteBalance: 0,
                remainingAmount: 0,
            });
        }
        ProductReport.forEach((y) => {
            let paidAmount = getFieldValue("paidAmount");
            let cutOffAmount = getFieldValue("cutOffAmount");
            let customDiscount = getFieldValue("customDiscount");
            paidAmount = paidAmount ? fixed2Digit(paidAmount) : 0;
            cutOffAmount = cutOffAmount ? fixed2Digit(cutOffAmount) : 0;
            customDiscount = customDiscount ? fixed2Digit(customDiscount) : 0;
            totalAmount += Number(y.netAmount);
            netAmount = Number(totalAmount + cutOffAmount - customDiscount);
            quantity += Number(y.quantity);
            balance =
                paidAmount || cutOffAmount || customDiscount
                    ? totalAmount - paidAmount + cutOffAmount - customDiscount
                    : totalAmount;

            if (balance < 0) {
                balance = "Not valid Paid Amount";
                props.form.setFieldsValue({
                    totalAmount: totalAmount ? fixed2Digit(totalAmount) : 0,
                    finalAmount: netAmount? netAmount.toFixed(2) : 0,
                    quantity: quantity,
                    balance: 0,
                });
            } else {
                props.form.setFieldsValue({
                    totalAmount: totalAmount.toFixed(2),
                    finalAmount: netAmount? netAmount.toFixed(2) : 0,
                    quantity: quantity,
                    balance: balance ? balance.toFixed(2) : 0,
                });

            }
        });
    };

    const fetchBanks = async () => {
        const {data} = await fetchBank({
            type: "bank",
            results: 100,
            count: 100,
        });
        setBanks(data);
    };

    // useEffect(() => {
    //     if (CreditNoteBalance) {
    //         const finalBalance = getFieldValue("balance") - CreditNoteBalance;
    //         const remainingAmount = getFieldValue("totalAmount") - CreditNoteBalance;
    //         const finalAmount = getFieldValue("finalAmount") - CreditNoteBalance;
    //         props.form.setFieldsValue({
    //             balance: finalBalance,
    //             remainingAmount: remainingAmount,
    //             finalAmount: finalAmount,
    //         });
    //     }
    // }, [CreditNoteBalance]);

    const fetchSaleFrom = async () => {
        const {data} = await fetchSaleCategory({});
        setSaleCategory(data);
    };

    useEffect(() => {
        props.form.setFieldsValue({
            paidAmount: 0,
            paymentType: "None",
        });
        fetchBanks();
        fetchSaleFrom();
    }, []);

    useEffect(() => {
        if (ProductReport && saleData) {
            calNetAmount();
        }
        if (getFieldValue('paidAmount') == 0 || getFieldValue('paidAmount') == undefined || getFieldValue('paidAmount') == null){
            setFormData(initialBankState)
        }
    }, [
        ProductReport,
        getFieldValue("paidAmount"),
        getFieldValue("cutOffAmount"),
        getFieldValue("customDiscount"),]);

    useEffect(() => {
        let x = new URLSearchParams(window.location.search);
        setSaleId(x.get("_id"));
    }, []);

    const handleSubmit = async (e) => {
        const {form} = props;
        e.preventDefault();

        form.validateFields(async (err, valData) => {
            if (!err) {
                let findStateCode = _.find(States, (item) => {
                    return item.name === valData.state;
                });
                if (findStateCode) {
                    valData.stateCode = findStateCode.stateCode;
                }
                valData.productReports = ProductReport;
                valData.customerId = customerId;
                valData.paidToUserId = paidToUserId;
                valData.paidToUser = formData;
                valData.saleId = saleId;
                valData.invoiceNumber = inoviceNumber;

                const sumFormDataAmount = formData.reduce(
                    (sum, item) => sum + parseFloat(item.amount) || 0,
                    0
                );

                if (ProductReport.length == 0) {
                    notification.error({
                        message: "Please Add Products",
                    });
                    return;
                }

                const finalAmount = valData.finalAmount;
                const paidAmount = valData.paidAmount;
                if (paidAmount < 0) {
                    notification.error({
                        message: "Paid Amount cannot be negative",
                    });
                    return;
                }
                if (parseFloat(paidAmount) > parseFloat(finalAmount)) {
                    notification.error({
                        message: "Received amount cannot be Greater than Final Amount",
                    });
                    return null;
                }

                if (!valData.totalAmount) {
                    notification.warning({message: "Please Add Some Items"});
                    return;
                }

                if (!valData.customerId) {
                    notification.warning({message: "Please Choose Client"});
                    return;
                }

                if (!valData.saleCategory) {
                    notification.warning({message: "Please Add Sale Category"});
                    return;
                }

                if (!valData.stateOfSupply) {
                    notification.warning({message: "Please Add State of Supply"});
                    return;
                }

                if (parseFloat(valData.paidAmount) < sumFormDataAmount) {
                    notification.error({
                        message: "Paid amount Cannot Be Greater Than Total Amount",
                    });
                    return;
                } else if (parseFloat(valData.paidAmount) > sumFormDataAmount) {
                    notification.error({
                        message: "Paid amount Cannot Be Less Than Total Amount",
                    });
                    return;
                }

                if (valData.customDiscount && !valData.client) {
                    notification.warning({message: "Please Choose Client for Cash Discount"});
                    return;
                }

                if (valData.balance == 0) {
                    valData.paymentStatus = "Done";
                } else {
                    valData.paymentStatus = "Pending";
                }
                if (valData.saleCategory) {
                    const includeBalance = saleCategory.find((item) => {
                        return item._id == valData.saleCategory;
                    });
                    valData.saleCategoryName = includeBalance.name;
                }
                valData.includeBalance = includeBalance;

                let x = await dispatch(editSaleFxn(valData));
                if (x && x.success) {
                    notification.success({message: x.message});
                    setProductReport([]);
                    updateProductReport();
                    props.form.setFieldsValue({});
                    setTimeout(() => {
                        navigate("/sale");
                    }, 1000);
                } else {
                    notification.error({message: x.message});
                }
            }
        });
    };

    useEffect(() => {
        if (props.form.getFieldValue("saleCategory")) {
            const includeBalance = saleCategory.find((item) => {
                return item._id == props.form.getFieldValue("saleCategory");
            });
            setIncludeBalance(includeBalance?.includeBalance);
        }
    }, [props.form.getFieldValue("saleCategory")]);

    let inputTypes = {
        upperFields: [
            {
                key: "salesDate",
                label: "Date of Sale",
                disabled: true,
                type: "date",
                span: "col-md-3",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        salesDate: x,
                    });
                },
            },
            {
                key: "saleCategory",
                label: "Sale Category*",
                span: "col-md-3",
                placeholder: "Choose One",
                type: "select",
                options: saleCategory,
                showSearch: true,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        saleCategory: x,
                    });
                },
            },
            {
                key: "stateOfSupply",
                label: "State of Supply*",
                span: "col-md-3",
                placeholder: "State of supply",
                type: "select",
                options: IndianStates,
                showSearch: true,
                keyAccessor: (x) => x.stateCode,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        stateOfSupply: x,
                    });
                },
            },
            {
                key: "otherState",
                label: "Other State",
                span: "col-md-3",
                placeholder: "Other State",
                type: "text",
                // hidden: getFieldValue('stateOfSupply') !== -1
            },
            {
                key: "orderId",
                label: "Order ID",
                span: "col-md-3",
                placeholder: "Type here..",
                required: !includeBalance,
            },
            {
                key: "trackingDetails",
                label: "Tracking Details",
                span: "col-md-3",
                placeholder: "Tracking Details",

            },
            {
                key: "shipThrough",
                label: "Ship Through",
                span: "col-md-3",
                placeholder: "Ship Through",
                type: "select",
                options: shipthrough,
                showSearch: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        shipThrough: x,
                    });
                },
            },
        ],

        lowerFields: [
            {
                key: "totalAmount",
                label: "Total Amount *",
                span: "col-md-2",
                placeholder: "Total Amount",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        totalAmount: netAmount,
                    });
                },
                disabled: true,
            },
            {
                key: "cutOffAmount",
                label: "Round Off Amount",
                type: "number",
                span: "col-md-2",
                placeholder: "Cutoff Amount",
            },
            {
                key: "customDiscount",
                label: "Cash Discount",
                type: "number",
                span: "col-md-2",
                placeholder: "Custom Discount",
            },
            {
                key: "client",
                label: "Expense*",
                span: "col-md-2",
                placeholder: "Select From Expense Head",
                type: "select",
                options: clientData,
                onSearch: (value) => {
                    getClient({name: value})
                },
                showSearch: true,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        client: x,
                    });
                },
                hidden: !getFieldValue("customDiscount")
            },
            {
                key: "finalAmount",
                label: "Final Amount",
                type: "number",
                span: "col-md-2",
                placeholder: "Final Amount",
                disabled: true,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        finalAmount: netAmount,
                    });
                },
            },
            {
                key: "paidAmount",
                type: "number",
                label: "Received Amount",
                span: "col-md-2",
                placeholder: "Received Amount",
                hidden: !includeBalance,
            },
            {
                key: "balance",
                label: "Balance *",
                span: "col-md-2",
                placeholder: "Balance",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        balance: balance,
                    });
                },
                disabled: true,
            },
        ],
    };

    // useEffect(() => {
    //   if (customerState) {
    //     props.form.setFieldsValue({
    //       stateOfSupply: customerState,
    //     });
    //   }
    // }, [customerState]);

    return (
        <PageHeader title={"Edit Sales"}>
            <div className="card">
                <div className="card-body">
                    <div className={"row"}>
                        <div className={"col-md-3 mb-3"}>
                            <InputBox title={"Client"}>
                                <input
                                    style={{height: "45px", color: "black"}}
                                    disabled
                                    className={"form-control"}
                                    type="text"
                                    value={customerName}
                                />
                            </InputBox>
                        </div>
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <div className="form-elements-wrapper">
                            <div className={"row"}>
                                {inputTypes.upperFields.map((item, key) => {
                                    return !item.hidden ? (
                                        <div
                                            className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                                            key={key}
                                        >
                                            <GetEachFormFields {...props.form} item={item}/>
                                        </div>
                                    ) : null;
                                })}
                            </div>
                            <div className={"row"} key={getFieldValue("stateOfSupply")}>
                                <div className={"col-md-12 mt-4"}>
                                    <ItemListComponent
                                        ProductReport={ProductReport}
                                        customerId={customerId}
                                        updateProductReport={updateProductReport}
                                        stateOfSupply={getFieldValue("stateOfSupply")}
                                        companyState={companyStateCode}
                                    />
                                </div>
                            </div>

                            <div className={"row"}>
                                {inputTypes.lowerFields.map((item, key) => {
                                    return !item.hidden ? (
                                        <div
                                            className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                                            key={key}
                                        >
                                            <GetEachFormFields {...props.form} item={item}/>
                                        </div>
                                    ) : null;
                                })}
                            </div>

                            {getFieldValue("paidAmount") ? (
                                <Card title={"Payments"} size={"small"}>
                                    <div className="d-flex align-items-end">
                                        <div>
                                            {formData.map((data, index) => (
                                                <div key={index} className={"item-flex mt-2"}>
                                                    <InputBox title={"Banks"}>
                                                        <select
                                                            style={{height: "45px"}}
                                                            className="form-control"
                                                            value={data.bankId}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    index,
                                                                    "bankId",
                                                                    e.target.value
                                                                )
                                                            }
                                                            required="true"
                                                        >
                                                            <option value="">Select Bank</option>
                                                            {banks.map((bank) => (
                                                                <option key={bank._id} value={bank._id}>
                                                                    {bank.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </InputBox>

                                                    <InputBox title={"Payment Type"}>
                                                        <select
                                                            style={{
                                                                height: "45px",
                                                            }}
                                                            className="form-control"
                                                            value={data.paymentType}
                                                            required
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    index,
                                                                    "paymentType",
                                                                    e.target.value
                                                                )
                                                            }
                                                        >
                                                            <option value="">Select Payment Type</option>
                                                            {PaymentTypes.map((PaymentTypes) => (
                                                                <option>{PaymentTypes.name}</option>
                                                            ))}
                                                        </select>
                                                    </InputBox>

                                                    <InputBox title={"Amount"}>
                                                        <input
                                                            style={{
                                                                height: "45px",
                                                            }}
                                                            className={"form-control"}
                                                            type="number"
                                                            value={data.amount}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    index,
                                                                    "amount",
                                                                    e.target.value
                                                                )
                                                            }
                                                            placeholder="Amount"
                                                        />
                                                    </InputBox>

                                                    <InputBox title={"Transaction Details"}>
                                                        <input
                                                            style={{
                                                                height: "45px",
                                                            }}
                                                            className={"form-control"}
                                                            value={data.transactionDetails}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    index,
                                                                    "transactionDetails",
                                                                    e.target.value
                                                                )
                                                            }
                                                            placeholder="Transaction Details"
                                                        />
                                                    </InputBox>
                                                    <InputBox className="deleteBtn">
                                                        <div key={index} className={"item-flex mt-3"}>
                                                            {index !== 0 && showDeleteButtons[index] && (
                                                                <a
                                                                    className="empty_btn"
                                                                    onClick={() => handleDelete(index)}
                                                                >
                                                                    <i className="fa fa-trash-alt"></i>
                                                                </a>
                                                            )}
                                                        </div>
                                                    </InputBox>
                                                </div>
                                            ))}
                                        </div>
                                        <div>
                                            <InputBox className={"pb-2"}>
                                                <a
                                                    className="addBtn  btn-link"
                                                    onClick={handleAddField}
                                                >
                                                    Add More
                                                </a>
                                            </InputBox>
                                        </div>
                                    </div>
                                </Card>
                            ) : null}
                        </div>

                        <button type="submit" className="btn btn-gradient-info me-2">
                            Submit
                        </button>
                        <HyperLink className=" ms-4 btn btn-danger" link={"/sale"}>
                            Cancel
                        </HyperLink>
                    </Form>
                </div>
            </div>
        </PageHeader>
    );
}

export default Form.create()(EditSales);
