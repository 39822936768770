import React, { useEffect, useState } from "react";
import {HyperLink} from "../../components/Elements/appUtils";
import { Form } from "../../components/Elements/appUtils";
import notification from "../../components/Elements/Notification";
import { useDispatch } from "react-redux";
import { leadSourceOption } from "../../components/_utils/_utils";
import {GetEachFormFields} from "../../components/_utils/formUtils"
import PageHeader from "../../components/Elements/pageHeader";

import _ from "lodash";

import {useNavigate} from "react-router-dom";

import { addLeadFxn } from "./action";
import {fetchSaleCategory} from "../category/actions";

const AddLead = (props) => {
  const [saleCategory, setSaleCategory] = useState([]);
 

  const navigate = useNavigate();

  let dispatch = useDispatch();

  const fetchSaleFrom = async () => {
    const {data} = await fetchSaleCategory({});
    setSaleCategory(data);
  };

  useEffect(()=>{
    fetchSaleFrom();
  },[])
  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
       
        valData.type = "employee";

        if (!valData.name) {
          notification.warning({ message: "Please Enter Name" });
          return;
        }

        if (!valData.mobile) {
          notification.warning({ message: "Please Enter Number" });
          return;
        }
        //
        // if (!valData.state) {
        //   notification.warning({ message: "Please Select State" });
        //   return;
        // }
        //



        let x = await dispatch(addLeadFxn(valData));
        if (x && x.success) {
        //   navigate('/employee')
      
          props.form.setFieldsValue({
            name: "",
            email: "",
            mobile: "",
            state: "",
            city: "",
            comments: "",
            pincode: "",
            leadSource: "",
            address:"",
            date: ""

          
         
          });
   
        }
      }
    });
  };

  



  let inputTypes = {
    fields: [
      {
        key: "name",
        label: "Name *",
        span: "col-md-6",
        placeholder: "Name",
      },
      {
        key: "mobile",
        label: "Mobile No *",
        span: "col-md-6",
        placeholder: "Mobile No",
      },
      {
        key: "email",
        label: "Email",
        span: "col-md-6",
        placeholder: "Email",
      },
      {
        key: "state",
        label: "State",
        type: "text",
        span: "col-md-6",
        placeholder: "State",
      },
      {
        key: "city",
        label: "City",
        type: "text",
        span: "col-md-6",
        placeholder: "City",
      },
      {
        key: "pincode",
        label: "Pincode",
        type: "text",
        span: "col-md-6",
        placeholder: "Pincode",
      },
      
      {
        key: "address",
        label: "Address",
        type: "text",
        span: "col-md-6",
        placeholder: "Address",
      },
      {
        key: "date",
        label: "Lead Date",
        showStar: true,
        placeholder: "Date",
        type: "date",
        span: "col-md-6",
        onChange: (x) => {
          props.form.setFieldsValue({
            date: x,
          });
        },
      },
      {
        key: "userName",
        label: "User Name",
        span: "col-md-6",
        placeholder: "Choose One",
        type: "select",
        options: saleCategory,
        showSearch: true,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            userName: x,
          });
        },
      },
      {
        key: "leadSource",
        label: "Lead Source",
        placeholder: "Please Choose Lead Source",
        type: "select",
        options: leadSourceOption,
        keyAccessor: (x) => x.option,
        valueAccessor: (x) => `${x.option}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            leadSource: x,
          });
        },
      },

      {
        key: "comments",
        label: "Comments",
        type: "text",
        span: "col-md-6",
        placeholder: "Comments",
      },
     
    ],
}

 

  return (
    <PageHeader title={'Add Lead'}>
    <Form onSubmit={handleSubmit}>
          <div className="card">
                <div className="card-body">
      <div className="form-elements-wrapper">
        <div className={"row"}>
          {inputTypes.fields.map((item, key) => {
            return !item.hidden ? (
              <div
                className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                key={key}
              >
                <GetEachFormFields {...props.form} item={item} />
              </div>
            ) : null;
          })}
        </div>
   
      </div>

      <button type="submit" className="btn btn-gradient-info me-2">
        Submit
      </button>
      <HyperLink className=" ms-4 btn btn-danger" link={"/lead-list"}>
        Cancel
      </HyperLink>
      </div>
      </div>
    </Form>
    </PageHeader>
  );
};

export default Form.create()(AddLead);
