import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import PageHeader from "../../components/Elements/pageHeader";
import {
  Button,
  DatePicker,
  Drawer,
  Modal,
  Form,
  displayDate,
  Table,
  notification,
} from "../../components/Elements/appUtils";
import moment from "moment";
import { FollowUpListFxn } from "./action";

const FollowUp = (props) => {
  let tableRef = useRef();

  const [followLists, setFollowLists] = useState([]);

  let { visible, onClose, lead } = props;

  const { getFieldDecorator } = props.form;
  let dispatch = useDispatch();

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      try {
        let obj = {
          leadId: lead?._id,
          sortField: "_id",
          sortOrder: "descend",
        };
        const data = await dispatch(FollowUpListFxn(obj));
        const currentDate = moment().format("YYYY-MM-DD");
        const filteredData = data.data.filter(
          (item) => moment(item.date).format("YYYY-MM-DD") === currentDate
        );

        const responseData = { data: filteredData };
        console.log(filteredData, "responseData");

        resolve(responseData);
      } catch (error) {
        console.error("Error fetching data:", error);
        resolve([]);
      }
    });
  };

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text, record, index) => index + 1,
    },
 
    {
      title: "Lead",
      dataIndex: "leadId",
      key: "leadId",
      render: (text, record, index) => {
        return <>{record?.leadId?.name}</>;
      },
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, record, index) => {
        return <>{moment(record.date).format("YYYY-MM-DD")}</>;
      },
    },
 
    {
      title: "Audio",
      key: "audio",
      render: (text, record, index) => (
        <audio controls>
          <source
            src={`http://localhost:8111/${record.audioPath}`}
            type="audio/mpeg"
          />
        </audio>
      ),
    },
  ];
  return (
    <>
   
      <PageHeader title={"Follow up"}>
        <div className="card">
          <div className="card-body">
            <Table apiRequest={apiRequest} columns={columns} ref={tableRef} />
          </div>
        </div>
      </PageHeader>
    </>
  );
};

export default Form.create()(FollowUp);
