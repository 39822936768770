import React, {useState, useEffect, useRef} from "react";

import {
    InputBox,
    Form,
    Card,
    notification,
} from "../../components/Elements/appUtils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {useNavigate} from "react-router-dom";
import {fetchOnlyServiceFxn} from "../products/actions";
import {fetchTakenDetails} from "../patients/components/action";
import {fetchFrequencyDetails} from "../patients/components/action";

import _ from "lodash";
import {getToken} from "../../request";
import axios from "axios";
import {getStockUrl} from "../stockRequest/api";
import TableMain from "../../components/Elements/Table";
import ServiceModal from "../patients/components/serviceModal";
import {useDispatch} from "react-redux";
import {updatePrescriptionFxn} from "../patients/components/action";
import {calculation, Tabs} from "../../components/Elements/appUtils";
import {findPrescriptionIdUrl, PrescriptionDetailUrl} from "./api";
import {dosage, getItemLocalstorage} from "../../components/_utils/_utils";
import {complain, diagnosis, testReport} from "../../components/_utils/appUtils";
import moment from "moment";
import PageHeader from "../../components/Elements/pageHeader";
import {AddFollowUpFxn} from "../lead/action";
import {fetchVisits, testUploadFxn} from "./actions";
import {apiUrl} from "../../setting";

let {TabPane} = Tabs;
let initState = {
    productId: "",
    productName: "",
    dosage: "",
    whentotake: "",
    routine: "",
    duration: "",
    remarks: "",
};
const EditPrescription = (props) => {
    
    const [patientRecord, setPatientRecord] = useState([])
    let id;
  const apiRequest1 = () => {
    return new Promise(async (resolve) => {
      try {
        let searchParams = new URLSearchParams(window.location.search);
        id = searchParams.get("_id");
       
        const response = await axios.get(PrescriptionDetailUrl(id), getToken());
        setPatientRecord(response.data.datas)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
  };

    useEffect(() => {
        apiRequest1()
    },[])
    const navigate = useNavigate();
    let {
        statesAreEqual,
        patientidrecord,
        patientNameRecord,
        visitId,
        doctorId,
    } = props;
    let initState = {
        productId: "",
        productName: "",
        dosage: "",
        whentotake: "",
        duration: "",
        frequency: "",
        remarks: "",
    };
    let secondValue = {
        serviceId: "",
        name: "",
    };
    let followValues = {
        days: "",
        nextday: "",
        weekday: "",
        remark: "",
    };
    let tableRef = useRef();
    const dispatch = useDispatch();
    const [stock, setStock] = useState([]);
    const [takenDetails, SetTakenDetails] = useState([]);
    const [frequencyDetails, SetFrequencyDetails] = useState([]);
    const [stockAll, setStockAll] = useState([]);
    const [serviceStock, setServiceStock] = useState([]);
    const [stockServiceAll, setStockServiceAll] = useState([]);
    const [allFollowUp, setFollowUpAll] = useState([]);
    const [prescriptionId, setPrescriptionId] = useState("");
    const [testFileData, setTestFileData] = useState([]);
    console.log(testFileData, "data")
    const [advice, setAdvice] = useState("");

    const apiRequest = async (params) => {
        try {
            const data = await fetchOnlyServiceFxn({...params});
            console.log(data, "Fdgfgfd")
            setServiceStock(data.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchStock = async () => {
        try {
            const response = await axios.get(getStockUrl(), getToken());
            console.log(response, "Fdgfgfd")
            const filteredStock = response.data.data.filter(
                (item) => item.productId.type === "Product"
            );
            setStock(filteredStock);
        } catch (error) {
            console.error("Error getting the data:", error);
        }
    };
    useEffect(() => {
        fetchStock();
        apiRequest();
        fetchTaken();
        fetchFrequency();
    }, []);

    useEffect(() => {
        checkPrescriptionId();
    }, []);

    const checkPrescriptionId = async () => {
        let searchParams = new URLSearchParams(window.location.search);
        let id = searchParams.get("_id");
        if (id) {
            handleId(id);
        }
    };

    const handleId = (id) => {
        return new Promise(async (resolve) => {
            const data = await axios.get(findPrescriptionIdUrl(id));

            setPrescriptionId(data.data.visitDoc);
            setTestFileData(data.data.visitDoc.testFilePath);
            setAdvice(data.data.visitDoc.advice);
            setFollowState({
                days: data.data.visitDoc.days,
                weekday: data.data.visitDoc.weekday,
                nextday: data.data.visitDoc.nextday,
                remark: data.data.visitDoc.remark,
            });
            setStockAll(data.data.visitDoc.products);
            setStockServiceAll(data.data.visitDoc.services);
            props.form.setFieldsValue({
                test: data.data.visitDoc.test,
                familyHistory: data.data.visitDoc.familyHistory,
                otherDisease: data.data.visitDoc.otherDisease,
                pastHistory: data.data.visitDoc.pastHistory,
                otherTest: data.data.visitDoc.otherTest,
                complaints: data.data.visitDoc.complaints,
                diagnosis: data.data.visitDoc.diagnosis,

            });
        });
    };
    let [productState, setProductState] = useState(initState);
    let [serviceState, setServiceState] = useState(secondValue);

    let [followState, setFollowState] = useState(followValues);
    const onSubmitMedicine = () => {
        if (!productState.productId) {
            notification.warning({message: "Please Choose Product"});
            return;
        }

        const updatedProductReport = {...productState};
        const updatedStockAll = [...stockAll, updatedProductReport];
        setStockAll(updatedStockAll);

        setProductState(initState);
    };

    const onSubmitServices = () => {
        if (!serviceState.serviceId) {
            notification.warning({message: "Please Choose Service"});
            return;
        }
        const updatedServiceReport = {...serviceState};

        const updatedStockServiceAll = [...stockServiceAll, updatedServiceReport];

        setStockServiceAll(updatedStockServiceAll);

        setServiceState(secondValue);
    };

    const handleChange1 = (e, fieldName) => {
        const {value} = e.target;

        let obj = {};
        if (fieldName == "productId") {
            const selectedProduct = stock.find(
                (prod) => prod.productId?._id === value
            );
            obj = {
                productName: selectedProduct.productId.name,
                hsnCode: selectedProduct.hsnCode,
                dosage: selectedProduct.dosage || 1,
                whentotake: selectedProduct.whentotake || "After Meal",
                duration: selectedProduct.duration || 1,
                routine: selectedProduct.frequency,
                remarks: selectedProduct.remarks,
            };
        }
        setProductState({
            ...productState,
            [fieldName]: value,
            ...obj,
        });
    };

    const getColumns = () => {
        let columnArr = [];
        _.each(columns, (item) => {
            if (!item.show) {
                columnArr.push(item);
            }
        });

        return columnArr;
    };

    const handleDelete = (index) => {
        reCalculateAmount();
        const updatedProductReport = [...stockAll];
        updatedProductReport.splice(index, 1);
        setStockAll(updatedProductReport);
    };

    const columns = [
        {
            title: "Sr. No",
            dataIndex: "serialNo",
            key: "serialNo",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Name",
            dataIndex: "productName",
            key: "productName",
        },
        {
            title: "Dosage",
            dataIndex: "dosage",
            key: "dosage",
        },
        {
            title: "When to take",
            dataIndex: "whentotake",
            key: "whentotake",
        },
        {
            title: "Duration",
            dataIndex: "duration",
            key: "duration",
        },
        {
            title: "Frequency.",
            dataIndex: "frequency",
            key: "frequency",
        },

        {
            title: "Action",
            key: "delete",
            render: (text, record, index) => (
                <a className="nav-link" onClick={() => handleDelete(index)}>
                    <i className="mdi mdi-delete"/>
                </a>
            ),
        },
    ];

    //File Upload Functionality Starts here..

    const fileInputRef = useRef(null);
    const [uploadTestData, setUploadTestData] = useState({
        testName: 'Trichoscopy Test',
        file: null,
    });

    const handleFileChange = (event) => {
        setUploadTestData(prevState => ({
            ...prevState,
            file: event.target.files[0]
        }));
    };

    const handleUploadFile = async () => {
        if (!uploadTestData.testName) {
            notification.warning({message: "Please Add Name"});
            return;
        }
        if (!uploadTestData.file) {
            notification.warning({message: "Please Choose File"});
            return;
        }

        let obj = {
            prescriptionId: prescriptionId._id,
            name: uploadTestData.testName
        }
        //upload api here
        const fd = new FormData();
        fd.append('obj', JSON.stringify(obj))
        fd.append("testFilePath", uploadTestData.file);

        let x = await dispatch(testUploadFxn(fd));
        if (!x.error) {
            setUploadTestData({
                testName: '',
                file: null,
            });
            fileInputRef.current.value = '';
            checkPrescriptionId();

        }
    };

    // const handleFileRemove = (index) => {
    //   const updateFileUploadData = [...finalUploadData];
    //   updateFileUploadData.splice(index, 1);
    //   setFinalUploadData(updateFileUploadData);
    // };

    const tableReload = () => {
        if (tableRef.current.reload) {
            tableRef.current.reload()
        }
    }

    useEffect(() => {
        tableReload();
    }, [testFileData]);

    const columns2 = [
        {
            title: "Sr. No",
            dataIndex: "serialNo",
            key: "serialNo",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Test Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "File Name",
            dataIndex: "filePath",
            key: "filePath",
            render: (text, record) => {
                return (
                    <a href={`${apiUrl}${text}`} target={'_blank'}>{`${record.name}`}</a>
                )
            },
        },
        {
            title: "Upload Date",
            dataIndex: "date",
            key: "date",
            render: (text) => <span>{moment(text).format("DD-MMM-YY")}</span>,
        },
        // {
        //   title: "Action",
        //   key: "delete",
        //   render: (text, record, index) => {
        //     return (
        //         <>
        //           <a className="empty_btn" onClick={() => handleFileRemove(index)}>
        //             <i className="fa fa-trash-alt"/>
        //           </a>
        //         </>
        //     );
        //   },
        // }
    ];

    //File Upload Functionality Ends here..

    const fetchTaken = (params) => {
        return new Promise(async (resolve) => {
            const data = await fetchTakenDetails({...params});
            SetTakenDetails(data.data);
        });
    };

    const fetchFrequency = (params) => {
        return new Promise(async (resolve) => {
            const data = await fetchFrequencyDetails({...params});
            SetFrequencyDetails(data.data);
        });
    };

    const handleAdviceChange = (e) => {
        const {value} = e.target;
        setAdvice(value);
    };
    const handleSubmit = async (e) => {
        const {form} = props;
        e.preventDefault();

        form.validateFields(async (err, valData) => {
            if (!err) {
                // if (stockAll.length === 0) {
                //     notification.warning({message: "Please select a product"});
                //     return;
                // }
                valData.products = stockAll;
                valData.advice = advice;
                valData.days = allFollowUp.days;
                valData.services = stockServiceAll;
                valData.days = followState.days;
                valData.nextday = followState.nextday;
                valData.weekday = followState.weekday;
                valData.remark = followState.remark;
                valData.patientId = patientidrecord;
                valData.patientName = patientNameRecord;
                valData.visitId = visitId;
                valData.doctorId = doctorId;
                valData._id = prescriptionId;
                let x = await dispatch(updatePrescriptionFxn(valData));
                if (x && x.success) {
                    setAdvice("");
                    setStockAll("");
                    setStockServiceAll("");
                    setFollowState(followValues);
                    navigate("/visit");
                }
            }
        });
    };
    const handleChange2 = (e, fieldName) => {
        const {value} = e.target;

        let objct = {};
        if (fieldName == "serviceId") {
            const selectedProduct = serviceStock.find((prod) => prod._id === value);
            objct = {
                name: selectedProduct.name,
            };
        }
        setServiceState({
            ...serviceState,
            [fieldName]: value,
            ...objct,
        });
    };

    const handleChange3 = (e, fieldName) => {
        const {value} = e.target;
        let nextday = followState.nextday;
        let weekday = followState.weekday;

        if (fieldName === "days") {
            const days = parseInt(value);

            if (!isNaN(days)) {
                const currentDate = new Date();
                const nextFollowupDate = new Date(
                    currentDate.setDate(currentDate.getDate() + days)
                );
                const day = nextFollowupDate.getDate();
                const month = nextFollowupDate.getMonth() + 1;
                const year = nextFollowupDate.getFullYear();

                nextday = `${day}/${month}/${year}`;
                const dayNames = [
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                ];
                const dayOfWeek = nextFollowupDate.getDay();
                weekday = dayNames[dayOfWeek];
            }
        }

        setFollowState({
            ...followState,
            [fieldName]: value,
            nextday: nextday,
            weekday: weekday,
        });
    };

    const reCalculateAmount = () => {
        calculation({...productState, statesAreEqual}).then((resp) => {
            setProductState({
                ...productState,
                ...resp,
            });
        });
    };
    useEffect(() => {
        reCalculateAmount();
    }, [
        productState.productId,
        productState.dosage,
        productState.duration,
        productState.routine,
        productState.remarks,
    ]);

    let inputTypes = {
        fields: [
            {
                key: "complaints",
                placeholder: "Please Choose Complaint",
                type: "select",
                showStar: true,
                span: "col-md-6",
                label: "Complaints",
                options: complain,
                multiple: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        complaints: x,
                    });
                },
            },
            {
                key: "familyHistory",
                label: "Family History",
                placeholder: "Enter Family History",
                type: "text",
                span: "col-md-6",
            },
            {
                key: "otherDisease",
                label: "Other Disease",
                placeholder: "Enter Other Disease",
                type: "text",
                span: "col-md-6",
            },
            {
                key: "pastHistory",
                label: "Past History",
                placeholder: "Enter Past History",
                type: "text",
                span: "col-md-6",
            },
            {

                key: "test",
                placeholder: "Please Choose Test",
                type: "select",
                showStar: true,
                span: "col-md-6",
                label: "Investigation",
                options: testReport,
                multiple: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        test: x,
                    });
                },
            },
            {
                key: "otherTest",
                label: "Other Investigation",
                placeholder: "Other Investigation",
                type: "text",
                span: "col-md-6",
            },
            {
                key: "diagnosis",
                placeholder: "Please Choose Diagnosis",
                type: "select",
                showStar: true,
                span: "col-md-12 mb-3",
                label: "Diagnosis",
                options: diagnosis,
                multiple: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        diagnosis: x,
                    });
                },
            },

        ]

    };


    return (
        <PageHeader title={"Edit Prescription"}>
            <div className="card">
                <div className="card-body">
                <div className={'d-flex -row mb-4'}>
                    {patientRecord.map((item) => {
                        return (
                            <div style={{ border: '2px solid grey', padding: '7px', borderRadius: '5px', display: 'inline-block' }}>
                                <div key={item._id} style={{ display: 'flex' }}>
                                    <div style={{ marginRight: '10px', fontWeight: 'bold' }}>Name:</div>
                                    <div>{item.patientId.name}</div>
                                    <div style={{ marginRight: '10px', marginLeft: '20px', fontWeight: 'bold' }}>Age :</div>
                                    <div>{item.patientId.age}</div>
                                    <div style={{ marginRight: '10px', marginLeft: '20px', fontWeight: 'bold' }}>UHID :</div>
                                    <div>{item.patientId.uhid}</div>
                                    <div style={{ marginRight: '10px', marginLeft: '20px', fontWeight: 'bold' }}>Mobile :</div>
                                    <div>{item.patientId.mobile}</div>
                                    <div style={{ marginRight: '10px', marginLeft: '20px', fontWeight: 'bold' }}>Address :</div>
                                    <div>
                                        {item.patientId.address && <span>{item.patientId.address} , </span>}
                                        {item.patientId.state && <span>{item.patientId.state} , </span>}
                                        {item.patientId.city}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    <div style={{textAlign:'right'}}>
                        <button
                            type="submit"
                            className="btn btn-gradient-info"
                            onClick={handleSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </div>

                    <Form onSubmit={handleSubmit}>
                        <div className={"row"}>
                            <form>
                                <div className={"row"}>
                                    {inputTypes.fields.map((item, key) => {
                                        return (
                                            <div
                                                className={`${item.span ? item.span : "col-md-12"}`}
                                                key={key}
                                            >
                                                <GetEachFormFields {...props.form} item={item}/>
                                            </div>
                                        );
                                    })}
                                </div>
                                <form>
                                    <Card title={"Select Tests"} size={"small"}>
                                        <div className={"item-flex"}>
                                            <InputBox>
                                                <div className={"alignCenter"}>
                                                    <InputBox title={"Name"}>
                                                        <input
                                                            placeholder={"Name"}
                                                            className={"form-control"}
                                                            value={uploadTestData.testName}
                                                            onChange={(e) => setUploadTestData(
                                                                {
                                                                    ...uploadTestData,
                                                                    testName: e.target.value,
                                                                }
                                                            )}
                                                        />
                                                    </InputBox>

                                                    <InputBox title={"Upload Test"}>
                                                        <input
                                                            ref={fileInputRef}
                                                            className={"form-control ms-3"}
                                                            type="file"
                                                            onChange={handleFileChange}
                                                        />
                                                    </InputBox>
                                                    <InputBox title={""}>
                                                        <div className={"alignCenter ms-4 mt-4"}>
                                                            <a
                                                                className={"empty_btn"}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handleUploadFile();
                                                                }}
                                                            >
                                                                <i className={"fa fa-plus"}/>
                                                            </a>
                                                        </div>
                                                    </InputBox>
                                                </div>
                                            </InputBox>
                                        </div>
                                    </Card>
                                </form>
                                <div  className="mt-4 mb-4">
                                    <h4 className="m-2">Tests</h4>
                                    <TableMain
                                        key={testFileData.length}
                                        dataSource={testFileData}
                                        columns={columns2}
                                        ref={tableRef}
                                    />
                                </div>
                                <Card title={"Select Medicine"} size={"small"}>
                                    <div className={"item-flex flex-wrap"}>
                                        <InputBox title={"Medicine"} className={"flex2"}>
                                            <div className="d-flex align-items-center">
                                                <select
                                                    className="form-control medwidth"
                                                    value={productState.productId}
                                                    onChange={(e) => handleChange1(e, "productId")}
                                                    required
                                                >
                                                    <option value="">Select Medicine</option>
                                                    {stock.map((stock) => (
                                                        <option
                                                            key={stock.productId._id}
                                                            value={stock.productId._id}
                                                        >
                                                            {stock.productId.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </InputBox>

                                        <InputBox title={"Dosage"}>
                                            <select
                                                className={"form-control medwidth "}
                                                placeholder={"Dosage"}
                                                value={productState.dosage}
                                                onChange={(e) => handleChange1(e, "dosage")}
                                                defaultValue="1"
                                            >
                                                <option value="">Select</option>
                                                {dosage.map((dosage) => (
                                                    <option key={dosage.option} value={dosage.option}>
                                                        {dosage.option}
                                                    </option>
                                                ))}
                                            </select>
                                        </InputBox>

                                        <InputBox title={"When to Taken"}>
                                            <select
                                                className="form-control medwidth"
                                                value={productState.whentotake || "After Meal"}
                                                onChange={(e) => handleChange1(e, "whentotake")}
                                                required
                                            >
                                                <option value="">Select</option>
                                                {takenDetails.map((taken) => (
                                                    <option key={taken._id} value={taken.whentotake}>
                                                        {taken.whentotake}
                                                    </option>
                                                ))}
                                            </select>
                                        </InputBox>

                                        <InputBox title={"Duration"}>
                                            <select
                                                className={"form-control medwidth"}
                                                placeholder={"Duration"}
                                                value={productState.duration}
                                                onChange={(e) => handleChange1(e, "duration")}
                                                defaultValue="1"
                                            >
                                                {Array.from(
                                                    {length: 60},
                                                    (_, index) => index + 1
                                                ).map((duration) => (
                                                    <option key={duration} value={duration}>
                                                        {duration} days
                                                    </option>
                                                ))}
                                            </select>
                                        </InputBox>
                                        <InputBox title={"Routine/Freq"}>
                                            <select
                                                className="form-control medwidth"
                                                value={productState.frequency}
                                                onChange={(e) => handleChange1(e, "frequency")}
                                                required
                                            >
                                                <option value="">Select</option>
                                                {frequencyDetails.map((item) => (
                                                    <option key={item._id} value={item.frequency}>
                                                        {item.frequency}
                                                    </option>
                                                ))}
                                            </select>
                                        </InputBox>

                                        <InputBox title={""}>
                                            <div className={"alignCenter mt-3"}>
                                                <a
                                                    className={"empty_btn"}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onSubmitMedicine();
                                                    }}
                                                >
                                                    <i className={"fa fa-plus"}/>
                                                </a>
                                                &nbsp;&nbsp;

                                            </div>
                                        </InputBox>
                                    </div>
                                </Card>
                            </form>
                            <div className="mt-4 mb-4">
                                <h4 className="m-2">Prescription</h4>
                                <TableMain key={stockAll.length} dataSource={stockAll} columns={getColumns()}/>
                            </div>

                            <InputBox title={"Advice / Observation"} className="mb-3">
                                <input
                                    placeholder={"advice"}
                                    className={"form-control"}
                                    name="advice"
                                    value={advice}
                                    onChange={handleAdviceChange}
                                    style={{height: "60px"}}
                                />
                            </InputBox>

                            <form>
                                <Card title={"Select Service"} size={"small"}>
                                    <div className={"item-flex"}>
                                        <InputBox>
                                            <div className={"alignCenter"}>
                                                <select
                                                    className="form-control medwidth"
                                                    onChange={(e) => handleChange2(e, "serviceId")}
                                                    value={serviceState.serviceId}
                                                    required
                                                >
                                                    <option value="">Select Service</option>
                                                    {serviceStock?.map((item) => (
                                                        <option key={item._id} value={item._id}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <InputBox title={""}>
                                                    <div className={"alignCenter ms-2"}>
                                                        <a
                                                            className={"empty_btn"}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                onSubmitServices();
                                                            }}
                                                        >
                                                            <i className={"fa fa-plus"}/>
                                                        </a>
                                                        &nbsp;&nbsp;
                                                    </div>
                                                </InputBox>
                                            </div>
                                        </InputBox>
                                    </div>
                                </Card>
                            </form>
                            <ServiceModal
                                stockServiceAll={stockServiceAll}
                                serviceState={serviceState}
                                setServiceState={setServiceState}
                                setStockServiceAll={setStockServiceAll}
                            />

                            <form>
                                <Card title={"Followup Visit"} size={"small"}>
                                    <div className={"item-flex"}>
                                        <InputBox title={"Days"}>
                                            <input
                                                placeholder={"Days"}
                                                className={"form-control"}
                                                name="days"
                                                value={followState.days}
                                                onChange={(e) => handleChange3(e, "days")}
                                            />
                                        </InputBox>

                                        <InputBox title={"Next Followup"}>
                                            <input
                                                placeholder={"Next Followup"}
                                                className={"form-control"}
                                                name="nextday"
                                                onChange={(e) => handleChange3(e, "nextday")}
                                                value={followState.nextday}
                                                // onChange={(e) => handleChange1(e, "frequency")}
                                            />
                                        </InputBox>

                                        <InputBox title={"Week Day"}>
                                            <input
                                                placeholder={"Week Day"}
                                                className={"form-control"}
                                                name="weekday"
                                                onChange={(e) => handleChange3(e, "weekday")}
                                                value={followState.weekday}
                                                // onChange={(e) => handleChange1(e, "frequency")}
                                            />
                                        </InputBox>
                                    </div>
                                    <InputBox title={"Remarks"} className="mt-4">
                                        <div className={"alignCenter"}>
                                            <input
                                                placeholder={"Remarks"}
                                                className={"form-control"}
                                                name="remark"
                                                onChange={(e) => handleChange3(e, "remark")}
                                                value={followState.remark}
                                            />
                                        </div>
                                    </InputBox>
                                </Card>
                            </form>

                            <button type="submit" className="btn btn-gradient-primary me-2">
                                Submit
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </PageHeader>
    );
};
export default Form.create()(EditPrescription);
