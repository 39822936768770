import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Modal,
  Table,
  Tooltip,
} from "../../components/Elements/appUtils";
import { DefaultTablePagination } from "../../components/Elements/appUtils";
import { Popconfirm } from "../../components/Elements/appUtils";
import { fetchProduct } from "./actions";
import PageHeader from "../../components/Elements/pageHeader";
import axios from "axios";
import {
  getbatchRecordByProductIdUrl,
  getbatchRecordByBatchUrl,
  deleteProductUrl,
} from "./api/index";
import { getToken } from "../../request";
import _, { set } from "lodash";
import { getItemLocalstorage } from "../../components/_utils/_utils";
import { useNavigate } from "react-router-dom";
import { notification } from "../../components/Elements/appUtils";
import { arraySorter } from "../../components/_utils/appUtils";
import moment from "moment/moment";
function ProductData() {
  const userType = getItemLocalstorage("user")["userType"];
  let tableRef = useRef();

  let showViewButton;
  if (userType === "doctor") {
    showViewButton = false;
  } else {
    showViewButton = true;
  }
  const [sortBy, setSortBy] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenForDetail, setIsModalOpenForDetail] = useState(false);
  const [k, setk] = useState(false);
  const [c, setC] = useState(false);
  const navigate = useNavigate();
  const [productId, setProductId] = useState(null);
  const [batchNo, setBatchNo] = useState(null);
  const [manufacturingDate, setmanufacturingDate] = useState(null);
  const [expiryDate, setExpireDate] = useState(null);
  const [filterState, setFilterState] = useState(`initFilter`);
  const [productData, setProductData] = useState([]);
  const [sortedProducts, setSortedProducts] = useState([]);
  const [searchProductName, setSearchProductName] = useState("");
  const [searchHsnCode, setSearchHsnCode] = useState("");
  const [searchType, setSearchType] = useState("");
  const [searchCode, setSearchCode] = useState("");
  const [searchDescription, setSearchDescription] = useState("")
  console.log(sortedProducts, "this is sorted data");

  const fetchBatch = async () => {
    try {
      const response = await axios.get(getbatchRecordByProductIdUrl(), {
        params: { productId },
        ...(await getToken()),
      });
      return response.data;
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  const fetchBatchList = async () => {
    try {
      const response = await axios.get(getbatchRecordByBatchUrl(), {
        params: { productId, manufacturingDate, batchNo, expiryDate },
        ...(await getToken()),
      });

      return response.data;
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await fetchProduct({ ...params });


        const sortedArray = arraySorter(data.data)
        console.log(data, "fetchProduct")
        setProductData(sortedArray);
        resolve(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
  };

  const filterProducts = () => {
    if (!searchProductName && !searchHsnCode && !searchType && !searchCode && !searchDescription) {
      setSortedProducts([]);
    } else {
        const filteredProducts = productData.filter((product) =>
          (!searchProductName || product.productName.toLowerCase().includes(searchProductName.toLowerCase())) &&
          (!searchHsnCode || product.productId.hsnCode.toLowerCase().includes(searchHsnCode.toLowerCase())) &&
          (!searchType || product.productId.type.toLowerCase().includes(searchType.toLowerCase())) &&
          (!searchCode || product.productId.code.toLowerCase().includes(searchCode.toLowerCase())) &&
          (!searchDescription || product.productId.description.toLowerCase().includes(searchDescription.toLowerCase()))
        );
      setSortedProducts(filteredProducts);
      setProductData(filteredProducts)
    }
  };

  const clearFilters = async () => {
    setSearchProductName("");
    setSearchHsnCode("");
    setSearchType("");
    setSearchCode("");
    setSearchDescription("");
    setSortedProducts([]);

    try {
      const data = await fetchProduct();
      setProductData(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    if (tableRef.current) {
      tableRef.current.reload();
    }
  };

  useEffect(() => {
    fetchBatch();
  }, [productId]);

  useEffect(() => {
    apiRequest();
  }, []);

  const sortByName = () => {
    const sortedProducts = arraySorter(productData);
    setSortedProducts(sortedProducts);
    setSortBy('name');
  };

  const sortByCode = () => {
    const sortedProducts = [...productData].sort((a, b) => {
      const codeA = parseInt((a.productId.code || "").match(/\d+/)?.[0] || 0); // Extract numerical part and convert to integer
      const codeB = parseInt((b.productId.code || "").match(/\d+/)?.[0] || 0); // Extract numerical part and convert to integer
      setSortBy('code');
      return codeA - codeB;
    });

    setSortedProducts(sortedProducts);
  };

  const deleteProduct = async (id) => {
    console.log(id, "ppridyct");
    try {
      const response = await axios.delete(deleteProductUrl(id));
      console.log(response, "response");
      if (response.data.error) {
        notification.error({
          message: response.data.message,
        });
      } else {
        tableRef.current.reload();
        notification.success({
          message: response.data.message,
        });
      }
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };
  const columns = [
    {
      title: "Sr. No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text, record) => (
        <span>{record.productId && record.productId.type} </span>
      ),
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (text, record) => (
        <span>{record.productId && record.productId.code} </span>
      ),
    },
    {
      title: "HSN Code",
      dataIndex: "hsnCode",
      key: "hsnCode",
      render: (text, record) => (
        <span>{record.productId && record.productId.hsnCode} </span>
      ),
    },
    {
      title: "Packing",
      dataIndex: "packing",
      key: "packing",
      render: (text, record) => (
        <span>{record.productId && record.productId.packing} </span>
      ),
    },
    {
      title: "total Qty",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Gst Rate",
      dataIndex: "gst",
      key: "gst",
      render: (text, record) => (
        <span>{record.productId && record.productId.gst} </span>
      ),
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text, record) => (
        <span>{record.productId && record.productId.description} </span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "description",
      key: "description",
      hide: !showViewButton,
      render: (text, record) => (
        <>
          <Tooltip title={"View Batch"}>
            <a
              className={"empty_btn "}
              onClick={() => {
                setIsModalOpen(true);
                setProductId(record.productId._id);
              }}
            >
              <i className={"fa fa-eye far"}/>
            </a>
          </Tooltip>

          {userType == "company" && (
            <>
              <Tooltip title={"Edit Product"}>
                <a
                  onClick={() => {
                    navigate(`/edit-product?_id=${record.productId._id}`);
                  }}
                >
                  <img
                    style={{ height: "30px", width: "30px" }}
                    src={"./edit.svg"}
                  />
                </a>
              </Tooltip>
              <Tooltip title={"Delete"} className="ml-4">
                <Popconfirm
                  title={"Are your sure, you want to delete Product?"}
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => {
                    deleteProduct(record.productId?._id);
                  }}
                >
                  <img
                    className="ms-2"
                    style={{ height: "30px", width: "30px" }}
                    src={"./close.svg"}
                  />
                </Popconfirm>
              </Tooltip>
            </>
          )}
        </>
      ),
    },
  ];

  const batchColumn = [
    {
      title: "Batch Number",
      dataIndex: "batchNo",
      key: "batchNo",
      //
    },
    {
      title: "Mfg Date",
      dataIndex: "manufacturingDateString",
      key: "manufacturingDateString",
    },
    {
      title: "Exp Date",
      dataIndex: "expiryDateString",
      key: "expiryDateString",
    },
    {
      title: "Qty.",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Actions",
      dataIndex: "description",
      key: "description",
      hide: !showViewButton,
      render: (text, record) => (
        <>
          <Tooltip title={"View Batch Details"}>
            <a
              className={"empty_btn"}
              onClick={() => {
                setIsModalOpenForDetail(true);
                setProductId(record.productId);
                setmanufacturingDate(record.manufacturingDate);
                setExpireDate(record.expiryDate);
                setBatchNo(record.batchNo);
              }}
            >
              <i className={"fa fa-eye far"}/>
            </a>
          </Tooltip>
        </>
      ),
    },
  ];

  const batchList = [
    {
      title: "Batch Number",
      dataIndex: "batchNo",
      key: "batchNo",
      render: (text, record) => <span>{record && record.batchNo} </span>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text, record) => <span>{record && record.type} </span>,
    },
    {
      title: "Transaction Type",
      dataIndex: "transactionType",
      key: "transactionType",
      render: (text, record) => (
        <span>{record && record.transactionType} </span>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => <span>{record && record.quantity} </span>,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, record) => <span>{record && record.date? (moment(record.date).format('DD/MM/YYYY')):null} </span>,
    },
  ];

  const getColumns = () => {
    let columnArr = [];
    _.each(columns, (item) => {
      if (!item.hide) {
        columnArr.push(item);
      }
    });
    return columnArr;
  };

  let initFilter = {
    productName: undefined,
  };
  const events = {
    reloadTable: () => {
      tableRef.current.reload();
    },
    updateFilter: (data) => {
      setFilterState((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
    },
    clearFilter: () => {
      setFilterState((prevState) => {
        return {
          ...prevState,
          ...initFilter,
        };
      });
      setTimeout(() => {
        tableRef.current.reload();
      }, 500);
    },
  };

  return (
    <PageHeader
      title={"Products"}
      extraLink={
        userType == "company"
          ? [
              {
                name: "Add Product",
                link: "/add-product",
              },
            ]
          : []
      }
    >
      <div className="card">
        <div className="card-body">
        <div className="mb-3 row">
        <div className="col-md-2 mb-2 pe-0">
          <label htmlFor="searchProductName" className="form-label" />

          <input
            type="text"
            className="form-control search-filters"
            id="searchProductName"
            placeholder="Search by Product Name"
            value={searchProductName}
            onChange={(e) => setSearchProductName(e.target.value)}
          />
        </div>
        <div className="col-md-2 mb-2 pe-0" style={{paddingLeft : '7px'}}>
        <label htmlFor="searchType" className="form-label"/>
        <input
          type="text"
          className="form-control search-filters"
          id="searchType"
          placeholder="Search by type"
          value={searchType}
          onChange={(e) => setSearchType(e.target.value)}
        />
      </div>
      <div className="col-md-2 mb-2 pe-0" style={{paddingLeft : '7px'}}>
      <label htmlFor="searchCode" className="form-label"/>
      <input
        type="text"
        className="form-control search-filters"
        id="searchCode"
        placeholder="Search by  Code"
        value={searchCode}
        onChange={(e) => setSearchCode(e.target.value)}
      />
    </div>
        <div className="col-md-2 mb-2 pe-0" style={{paddingLeft : '7px'}}>
          <label htmlFor="searchHsnCode" className="form-label"/>
          <input
            type="text"
            className="form-control search-filters"
            id="searchHsnCode"
            placeholder="Search by HSN Code"
            value={searchHsnCode}
            onChange={(e) => setSearchHsnCode(e.target.value)}
          />
        </div>
        <div className="col-md-2 mb-2 pe-0" style={{paddingLeft : '7px'}}>
          <label htmlFor="searchDescription" className="form-label"/>
          <input
            type="text"
            className="form-control search-filters"
            id="searchDescription"
            placeholder="Search by Description"
            value={searchDescription}
            onChange={(e) => setSearchDescription(e.target.value)}
          />
        </div>




        </div>
        <div className='mb-2 search-buttons'>
        <div className="search-wrap pe-2">
        <button className="btn btn-info" onClick={filterProducts}>
          Search
        </button>
      </div>
      <div className="search-wrap pe-2">
        <button className="btn btn-secondary" onClick={clearFilters}>
          Clear
        </button>
      </div>
     

</div>

          <h4>Sort Product : </h4>
          <button
            onClick={() => {
              sortByName();
              setk(true);
              setC(false);
            }}
            className={`btn ${sortBy === 'name' ? 'btn-info' : 'border-info text-info'} mb-4`}
          >
            Sort By Name
          </button>

          <button
            onClick={() => {
              sortByCode();
              setC(true);
              setk(false);
            }}
            className={`btn ${sortBy === 'code' ? 'btn-info' : 'border-info text-info'} mb-4 ms-2`}
          >
            Sort By Code
          </button>

          {sortedProducts.length === 0 ? (
            <Table
              dataSource={productData}
              columns={getColumns()}
              key={productData}
              pagination={DefaultTablePagination()}
            />
          ) : (
            <Table
              dataSource={sortedProducts}
              columns={getColumns()}
              key={k ? "k" : c ? "c" : "default"}
              pagination={DefaultTablePagination()}
            />
          )}
        </div>
      </div>
      {isModalOpen && (
        <Modal
          title={"Batch List"}
          visible={isModalOpen == true}
          onClose={() => setIsModalOpen(false)}
        >
          <div className={"mb50"}>
            <Table
              apiRequest={fetchBatch}
              columns={batchColumn}
              ref={tableRef}
            />
          </div>
        </Modal>
      )}{" "}
      {isModalOpenForDetail && (
        <Modal
          title={"Batch List Detail"}
          visible={isModalOpen == true}
          onClose={() => setIsModalOpenForDetail(false)}
        >
          <div className={"mb50"}>
            <Table
              apiRequest={fetchBatchList}
              pagination={DefaultTablePagination()}
              columns={batchList}
              ref={tableRef}
            />
          </div>
        </Modal>
      )}
    </PageHeader>
  );
}

export default ProductData;
